import React from 'react'
import {Text, Image, View, Page, Document, StyleSheet} from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {fontSize: 11, paddingTop: 20, paddingLeft: 40, paddingRight: 40, lineHeight: 1.5, flexDirection: 'column'},

    spaceBetween: {flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', color: "#3E3E3E"},

    titleContainer: {flexDirection: 'row', marginTop: 24},

    logoContainer: {flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: 20},

    logo: {width: 90},

    reportTitle: {fontSize: 16, textAlign: 'center'},

    addressTitle: {fontSize: 11, fontStyle: 'bold'},

    invoice: {fontWeight: 'bold', fontSize: 20},

    invoiceNumber: {fontSize: 11, fontWeight: 'bold'},

    address: {fontWeight: 400, fontSize: 10},

    theader: {marginTop: 20, fontSize: 10, fontStyle: 'bold', paddingTop: 4, paddingLeft: 7, flex: 1, height: 20, backgroundColor: '#DEDEDE', borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1},

    theader2: {flex: 2, borderRightWidth: 0, borderBottomWidth: 1},

    tbody: {fontSize: 9, paddingTop: 4, paddingLeft: 7, flex: 1, borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1},

    total: {fontSize: 9, paddingTop: 4, paddingLeft: 7, flex: 1.5, borderColor: 'whitesmoke', borderBottomWidth: 1},

    tbody2: {flex: 2, borderRightWidth: 1, },

    totalsWrap: {fontSize: 9, display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: 20},
    totalsSeparator: {borderTop: '1px solid #000', width: '20%', marginTop: 10},
    totalsText: {fontSize: 12, fontWeight: 'bold', textAlign: 'right', marginTop: 10}
})

const Invoice = ({invoiceData}) => {
    const InvoiceTitle = () => (
        <View style={styles.logoContainer}>
            <Image style={styles.logo} src={require('../../assets/DrinkLink_Logo-Horizontal-black-short.png')} />
        </View>
    );

    const Address = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View>
                    <Text style={styles.invoice}>Invoice</Text>
                    <Text style={styles.invoiceNumber}>Invoice number: {invoiceData.invoiceNumber} </Text>
                    <Text style={styles.invoiceNumber}>Paid by: {invoiceData.paidBy}</Text>
                </View>
                <View>
                    <Text style={styles.addressTitle}>Drinklink </Text>
                    <Text style={styles.addressTitle}>60909 Crested Butte ln</Text>
                    <Text style={styles.addressTitle}>Bend, OR 97702</Text>
                    <Text style={styles.addressTitle}>Email: jcontreras@drinklink.com</Text>
                </View>
            </View>
        </View>
    );

    const UserAddress = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View style={{maxWidth: 200}}>
                    <Text style={styles.addressTitle}>Paid To: </Text>
                    <Text style={styles.address}>
                        {invoiceData.vendorAddress}
                    </Text>
                </View>
                <Text style={styles.addressTitle}>Date paid: {invoiceData.date}</Text>
            </View>
        </View>
    );

    const TableHead = () => (
        <View style={{width: '100%', flexDirection: 'row', marginTop: 10}}>
            <View style={[styles.theader, styles.theader2]}>
                <Text >Items</Text>
            </View>
            <View style={styles.theader}>
                <Text>Redeemed On</Text>
            </View>
            <View style={styles.theader}>
                <Text>Price</Text>
            </View>
            <View style={styles.theader}>
                <Text>Tip</Text>
            </View>
            <View style={styles.theader}>
                <Text>Amount</Text>
            </View>
        </View>
    );

    const TableBody = () => (
        invoiceData.drinks.map((item) => {
            console.log('item', item)
            return (
                <View key={item.id}>
                    <View style={{width: '100%', flexDirection: 'row'}}>
                        <View style={[styles.tbody, styles.tbody2]}>
                            <Text >{item.item}</Text>
                        </View>
                        <View style={styles.tbody}>
                            <Text >{item.dateRedeemed}</Text>
                        </View>
                        <View style={styles.tbody}>
                            <Text >{item.cost}</Text>
                        </View>
                        <View style={styles.tbody}>
                            <Text>{item.tip} </Text>
                        </View>
                        <View style={styles.tbody}>
                            <Text>{item.amount}</Text>
                        </View>
                    </View>
                </View>
            )
        })
    );

    const TableTotal = () => {
        return (
            <View style={styles.totalsWrap}>
                <View>
                    <Text>Price Total: $ {invoiceData.costTotal}</Text>
                </View>
                <View>
                    <Text>Tip Total: $ {invoiceData.tipTotal}</Text>
                </View>
                <View style={styles.totalsSeparator}></View>
                <View style={styles.totalsText}>
                    <Text>Total: $ {invoiceData.priceTotal}</Text>
                </View>
            </View>
        )
    }

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <InvoiceTitle />
                <Address />
                <UserAddress />
                <TableHead />
                <TableBody />
                <TableTotal />
            </Page>
        </Document>
    )
}

export default Invoice