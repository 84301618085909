import * as React from 'react';
import {createContext, useReducer, useEffect} from "react";

import VendorUserReducer from "./VendorUserReducer";
import {API, graphqlOperation} from "aws-amplify";
import {listVendorUsersWithVendorData} from "../graphql/customQueries";

const INITIAL_STATE = {
    userVendors: localStorage.getItem("user-vendors") !== 'undefined' ? JSON.parse(localStorage.getItem("user-vendors")) : [],
    userVendorSelected: localStorage.getItem("user-vendor-selected") !== 'undefined' ? JSON.parse(localStorage.getItem("user-vendor-selected")) : {}
};

export const VendorUserContext = createContext(INITIAL_STATE);

export const VendorUserContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(VendorUserReducer, INITIAL_STATE);
    //const {currentUser} = useContext(AuthContext);

    const combineDetails = (uVendors, vendors) => {
        let userVendorsDetails = [];
        for (let i = 0; i < uVendors.length; i++) {
            const thisVendor = vendors.find(itm => itm.id === uVendors[i].vendId);
            userVendorsDetails.push({
                ...uVendors[i],
                ...thisVendor
            });
        }
        return userVendorsDetails;
    }

    async function getAllVendors(userId) {

    }

    async function getUserVendors(userId) {
        console.log('in getUserVendors', userId)
        let userVendorsList = [];
        try {
            const userVendors = await API.graphql(graphqlOperation(listVendorUsersWithVendorData, {
                limit: 10,
                filter: {usersID: {eq: userId}}
            }));
            console.log('userVendorsxx:', userVendors);
            userVendorsList = userVendors.data.listVendorUsers.items;
            console.log('userVendorsList:', userVendorsList);
            const vendorList = userVendorsList.map(item => item.vendors);
            return vendorList;
        } catch (err) {
            console.log('error:', err);
        }
    }

    useEffect(() => {
        localStorage.setItem('user-vendors', JSON.stringify(state.userVendors));
    }, [state.userVendors]);

    useEffect(() => {
        localStorage.setItem('user-vendor-selected', JSON.stringify(state.userVendorSelected));
    }, [state.userVendorSelected]);

    return (
        <VendorUserContext.Provider value={({
            userVendors: state.userVendors,
            userVendorSelected: state.userVendorSelected,
            dispatch,
            getUserVendors,
            combineDetails
        })}>
            {children}
        </VendorUserContext.Provider>
    )
}