import * as React from 'react';
import { useState, useEffect, useContext, useRef } from 'react';
import {Html5QrcodeScanner, Html5QrcodeScannerState} from 'html5-qrcode';
import {API, graphqlOperation} from 'aws-amplify';
import {getMenuItemsPurchased} from "../../../src/graphql/queries";
import {Amplify} from 'aws-amplify';
import awsconfig from '../../../src/aws-exports';
import {updateMenuItemsPurchased} from '../../graphql/mutations';
import { Link, useNavigate } from "react-router-dom";
import Modal from '@mui/material/Modal';
import "./ScanDrinks.scss";
import { VendorUserContext } from '../../context/VendorUserContext';
import { formatMoney } from '../../utils/utils';
// import Html5QrcodePlugin from './Html5QrcodePlugin.jsx';
// import ResultContainerPlugin from './ResultContainerPlugin.jsx';
//import {Html5QrcodeScannerPlugin} from "./Html5QrcodeScannerPlugin";

// const CONFIG = {
//   fps: 4,
//   qrbox: {width: 300, height: 200},
//   formatsToSupport: [
//     Html5QrcodeSupportedFormats.CODE_128,
//     Html5QrcodeSupportedFormats.QR_CODE
//   ],
//   rememberLastUsedCamera: true,
//   supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA]
// }
// const QRCODE_REGION = 'SCANNER_EXAMPLE_QRCODE_REGION'
// const VERBOSE = true

Amplify.configure(awsconfig);

const ScanDrinks = () => {
    const navigate = useNavigate();
    const { userVendorSelected } = useContext(VendorUserContext);
    const [scanResult, setScanResult] = useState(null)
    const [redeemSuccess, setRedeemSuccess] = useState(false)
    const [redeemError, setRedeemError] = useState(false)
    const [redeemErrorMessage, setRedeemErrorMessage] = useState('')
    const [drinkName, setDrinkName] = useState('')
    const [drinkCost, setDrinkCost] = useState('')
    const [drinkTip, setDrinkTip] = useState('')
    //let scanner = null;
    const codeScanner = useRef(null);

    async function onScanSuccess(qrCodeMessage) {
        //scanner.clear();
        //{"drinkId":"1582dcf6-92a1-4218-a76e-cd63e8f915be","purchaser":{"id":"789113c0-5031-706a-f487-772f91be4c9e","avatarImage":null,"birthDate":null,"email":"test@some.com","fName":"Cheryl","homeCity":"Portland","homeState":"OR","isVendorUser":true,"lName":"Santiago","lowerCaseUsername":null,"phone":"5035771096","smsNotificationsAccept":true,"stripeCustomerId":"cus_PbdxHFwDOJMrL6","username":null,"emailNotificationsAccept":null,"userAskedToDelete":null,"deletedAt":null,"createdAt":"2024-02-22T00:53:50.860Z","updatedAt":"2024-02-25T01:33:46.892Z"}}
        setScanResult(qrCodeMessage)
        
        const scanResultJSON = JSON.parse(qrCodeMessage)
        const drinkId = scanResultJSON.drinkId;
        try {
            codeScanner.current?.clear().catch((e) => {
                console.error('error clearing scanner', e)
            })
            const drink = await API.graphql(graphqlOperation(getMenuItemsPurchased, { id: drinkId }));
            const theDrink = drink.data.getMenuItemsPurchased;
            const drinkStateHistory = JSON.parse(theDrink.stateHistory);
            drinkStateHistory.push({drink_status: 'redeemed', date: new Date().getTime()})
            if (theDrink.vendorsID !== userVendorSelected.id) {
                setRedeemError(true);
                setRedeemErrorMessage('This drink is from a different bar.')
            } else if (theDrink.state === 'redeemed') {
                setRedeemError(true)
                setRedeemErrorMessage('This drink has already been redeemed.')
            } else if (theDrink.state === 'purchased') {
                const updateInput = {
                    id: drinkId,
                    stateHistory: JSON.stringify(drinkStateHistory),
                    state: 'redeemed'
                }
                await API.graphql(graphqlOperation(updateMenuItemsPurchased, {input: updateInput}))
                setDrinkCost(theDrink.drinkCost)
                setDrinkName(theDrink.menuItemName)
                setDrinkTip(theDrink.tip)
                setRedeemSuccess(true)
                
            } else {
                setRedeemError(true)
                setRedeemErrorMessage('Unable to redeem drink.')
            }
        } catch (e) {
            console.log('error getting drink: ', e)
            setRedeemError(true)
            setRedeemErrorMessage('Unknown error has occured.  Please try again.')
        }
    } 

    useEffect(() => {
        const initiatedStates = [
            Html5QrcodeScannerState.SCANNING,
            Html5QrcodeScannerState.PAUSED
          ]
        if (!codeScanner.current || initiatedStates.includes(codeScanner.current?.getState())) {
            codeScanner.current = new Html5QrcodeScanner('reader', { 
                fps: 5, 
                qrbox: {
                width: 500,
                height: 500
                } 
            }, false)
            codeScanner.current?.render(onScanSuccess, () => {
                // nothing scanned
            })
        }

        return () => {
            if (codeScanner.current && 
                initiatedStates.includes(codeScanner.current?.getState())
            ) {
                codeScanner.current?.clear().catch((e) => {
                    console.error('error clearing scanner', e)
                })
            }
        }
    }, [])

    return (
        <div>
            <h1>Scan Drinks</h1>
            <div id="reader"></div>
            {redeemSuccess && 
                <Modal
                    open={redeemSuccess}
                    onClose={() => navigate('/drinks')}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className='redeem-success'>
                        <h2 className="redeem-success-h2">Drink Redeemed!</h2>
                        <h3 className="redeem-success-h3">Drink: {drinkName}</h3>
                        <h3 className="redeem-success-h3">Value: {formatMoney(drinkCost).toString()}</h3>
                        <h3 className="redeem-success-h3">Tip: {formatMoney(drinkTip).toString()}</h3>
                        <div className="redeem-success-button" onClick={() => navigate('/drinks')}>Done</div>
                    </div>
                </Modal>
            }
            {redeemError && 
                <Modal
                    open={redeemError}
                    onClose={() => navigate('/drinks')}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className='redeem-error'>
                        <h2>{redeemErrorMessage}</h2>
                        <div className="redeem-error-button" onClick={() => navigate('/drinks')}>Done</div>
                    </div>
                </Modal>
            }
        </div>
    )
}

export default ScanDrinks;