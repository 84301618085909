import "./menu-items-datatable.scss";
import * as React from 'react';
import {API, graphqlOperation} from "aws-amplify";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import {menuItemsByDrinkAllData} from "../../../src/graphql/customQueries";
import { 
    updateMenuItems, 
    createMenuItems, 
    deleteMenuItems, 
    createMainCategories,
    createSubCategories
} from "../../../src/graphql/mutations";
import { exampleMenuItemsData} from './exampleMenuItemsData'

import { listMainCategories } from "../../../src/graphql/customQueries";
import { RotatingLines } from 'react-loader-spinner'

import { menuItemColumns, menuItemLinks } from "./menuitemsdatatablesource";

import UploadMenuItemCSVModal from "./upload-menu-item-csv";
import EditMenuItemModal from "./edit-menu-item-modal";
import EditCategoriesModal from "./edit-categories-modal";
import { VendorUserContext } from "../../context/VendorUserContext";

const MenuItemDataTable = () => {
    const [menuItemsData, setMenuItemsData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [editingMenuItem, setEditingMenuItem] = useState(null);
    const [csvUploadModalOpen, setCsvUploadModalOpen] = useState(false);
    const [editMenuItemModalOpen, setEditMenuItemModalOpen] = useState(false);
    const [editMenuItemType, setEditMenuItemType] = useState('new');
    const [editCategorySubCategoryModalOpen, setEditCategorySubCategoryModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [posSelected, setPosSelected] = useState('drinklink')
    const navigate = useNavigate();

    const handleCsvUploadModalClose = () => setCsvUploadModalOpen(false);
    const handleEditMenuItemModalClose = () => setEditMenuItemModalOpen(false);

    const { userVendorSelected } = useContext(VendorUserContext);

    useEffect(() => {
        console.log('changed posProviderType to: ', posSelected)
    }, [posSelected])

    const getmenuItemsData = async () => {
        let nextTokenReturn
        let allMenuItems = []
        try {
            // graphql only returns 100 docs max so need to cycle through pagination
            do {
                const menuItemsReturn = await API.graphql(graphqlOperation(
                    menuItemsByDrinkAllData, {
                        filter: {
                            vendorsID: {eq: userVendorSelected.id}
                        },
                        type: "menuItem",
                        nextToken: nextTokenReturn
                    }
                ));
                nextTokenReturn = menuItemsReturn.data?.menuItemsByDrink?.nextToken
                const fetchedMenuItems =  menuItemsReturn.data.menuItemsByDrink.items;
                allMenuItems = allMenuItems.concat(fetchedMenuItems)
            } while (nextTokenReturn)

            const filteredMenuItems = allMenuItems.map(item => {
                return {
                    ...item, 
                    "mainCategoryName": item.mainCategory?.name ? item.mainCategory.name : 'Miscellaneous', 
                    subCategoryName: item.subCategory?.name ? item.subCategory.name : ''
                }
            });
            setMenuItemsData(filteredMenuItems);
        } catch (e) {
            console.log('error fetching menuItems', e)
        }
    }
    
    const getCategories = async () => {
        const categoriesDataReturn = await API.graphql(graphqlOperation(listMainCategories, {
            filter: {
                vendorsID: {eq: userVendorSelected.id}
            }
        }));
        let categoriesData = categoriesDataReturn.data.listMainCategories.items;
        setCategories(categoriesData);
    }

    const fetchData = async () => {
        try {
            await getmenuItemsData();
            await getCategories();
            setIsLoading(false);
        } catch (err) {
            console.log('error: ', err);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (userVendorSelected.id) {
            fetchData();
        }
    }, [userVendorSelected]);

    const handleEditCategorySubCategoryModalClose = async (hasDeleted) => {
        if (hasDeleted) {
            await getmenuItemsData();
            await getCategories();
        }
        setEditCategorySubCategoryModalOpen(false);
    }

    const handleEditCategorySubCategory = async () => {
        setEditCategorySubCategoryModalOpen(true);
    }

    const deleteMenuItem = async (menuItemId) => {
        try {
            const deleteResult = await API.graphql(graphqlOperation(deleteMenuItems, {input: {id: menuItemId}}));
            if (deleteResult) {
                return true;
            }
        } catch (e) {
            console.log(e);
            return false
        }
    }

    const handleDelete = async (id) => {
        const deleteResults = await deleteMenuItem(id);
        if (!deleteResults) {
            return;
        }
        // this will change when hooked up to DB
        setMenuItemsData(menuItemsData.filter((item) => item.id !== id));
    };

    // Parse sub and main categories
    const handleParseCSVUploadCategories = async (row, catsArray) => {
        const catIndex = catsArray.findIndex(cat => cat.name.toLowerCase() === row.category.toLowerCase());
        if (catIndex > -1) {
            // category already exists, now check for subcategory existing
            if (row.subCategory && row.subCategory !== "") {
                const thisSubCatsArray = catsArray[catIndex].subCategories;
                const subCatIndex = thisSubCatsArray.items.findIndex(subc => subc.name.toLowerCase() === row.subCategory.toLowerCase());
                //if (subCatIndex > -1) {
                if(subCatIndex > -1) {
                    // no change, category and sub category already exist
                    return {mainCatId: catsArray[catIndex].id, subCatId: catsArray[catIndex].subCategories.items[subCatIndex].id};
                }
                const newSub = {
                    name: row.subCategory.toLowerCase(),
                    vendorsID: userVendorSelected.id,
                    mainCategoriesID: catsArray[catIndex].id
                }
                const newCatAdded = await saveSubCategoryToDB(newSub);
                newSub.id = newCatAdded.id
                // add new subcategory
                catsArray[catIndex].subCategories.items.push(newSub);
                return {mainCatId: catsArray[catIndex].id, subCatId: newSub.id}
            }
            return {mainCatId: catsArray[catIndex].id, subCatId: null}
        }

        // category does not exist - which means either does sub.  Push both on.
        let newCat = {
            name: row.category.toLowerCase(),
            vendorsID: userVendorSelected.id
        }
        const newCatRes = await saveMainCategoryToDB(newCat);
        newCat.id = newCatRes.id;

        let newSubId = null
        // if there is a subcategory, add it.
        if (row.subCategory && row.subCategory.length > 0) {
            const newSub = {
                name: row.subCategory.toLowerCase(),
                vendorsID: userVendorSelected.id,
                mainCategoriesID: newCatRes.id
            }
            const newSubCat = await saveSubCategoryToDB(newSub);
            const newSubCategories = {
                items: [newSubCat]
            }
            newCat.subCategories = newSubCategories
            newSubId = newSubCat.id
        }
        
        catsArray.push(newCat);
        return {mainCatId: newCatRes.id, subCatId: newSubId}
    }

    const deleteAllMenuItemsForVendor = async () => {
        if (menuItemsData.length < 1) {
            return;
        }
        // const chunkSize = 50
        // const chunks = []
        // for (let i = 0; i < menuItemsData.length; i += chunkSize) {
        //     const chunk = menuItemsData.slice(i, i + chunkSize)
        //     chunks.push(chunk)
        // }
        // console.log('chunks', chunks)
        try {
            //for (const chnk of chunks) {
                console.log('calling delete')
                const itmsMutation = menuItemsData.map((itm, i) => {
                    return `mutation${i}: deleteMenuItems(input: {id: "${itm.id}"}) { id }`;
                });
                console.log('itemsMutation', itmsMutation)
                const res = await API.graphql(
                    graphqlOperation(`
                    mutation batchMutation {
                        ${itmsMutation}
                        }
                    `)
                );
                console.log('return success', res)
            //}
            return true;
        } catch (e) {
            console.log('error deleting menu items for vendor', e);
            return false;
        }
    }

    const setSquareColumnNames = (row) => {
        row.drink = row['Item Name'];
        row.description = (row['Description'] && row['Description'].length > 1) ? row['Description'] : (row['Variation Name'] ? row['Variation Name'] : null);
        row.price = row['Price'];
        row.category = row['Category'] && row['Category'].length > 1 ? row['Category'] : 'Miscellaneous';
        row.subCategory = row['Sub Category'] ? row['Sub Category'] : null
        return row;
    }

    const setToastColumnNames = (row) => {
        row.drink = row['Item Name'];
        row.description = (row['Description'] && row['Description'].length > 1) ? row['Description'] : (row['Variation Name'] ? row['Variation Name'] : null);
        row.price = row['Price'];
        row.category = row['Category'] && row['Category'].length > 1 ? row['Category'] : 'Miscellaneous';
        row.subCategory = row['Sub Category'] ? row['Sub Category'] : null
        return row;
    }

    const setRowColumnsByType = (row, type) => {
        console.log('type ........', type);
        switch (type) {
            case 'square':
                const returnRow = setSquareColumnNames(row);
                console.log('returnRow', returnRow);
                return returnRow;
            case 'toast':
                return setToastColumnNames(row)
            case 'drinklink':
                return row
            default:
                console.log('not a type we support')
                return null;
        }
    }

    const handleParseCSV = async (csvData) => {
        let uploadCategories = [...categories];
        if (!Array.isArray(menuItemsData)) {
            // show toast error
            console.log('in return for isArray');
            return;
        }

        try {
            setIsLoading(true);
            //console.log('calling deleteallMenuItemsForVendor')
            await deleteAllMenuItemsForVendor();
        
            for (const rowIn of csvData) {
                const row = setRowColumnsByType(rowIn, posSelected)
                // only do something if the row has valid data
                if (!row.price || isNaN(parseInt(row.price))) {
                    continue
                }
                
                const {subCatId, mainCatId} = await handleParseCSVUploadCategories(row, uploadCategories);

                const newMenuItem = {
                    mainCategoriesID: mainCatId,
                    subCategoriesID: subCatId,
                    drink: row.drink,
                    description: row.description,
                    price: row.price,
                    vendorsID: userVendorSelected.id,
                    type: "menuItem"
                }
                const newMenuItemRes = await API.graphql(graphqlOperation(createMenuItems, {input: newMenuItem}))
            }
            await getmenuItemsData();
            await getCategories();
            setIsLoading(false);
        } catch (e) {
            console.log(e);
            setIsLoading(false);
        }
    }

    const saveNewMenuItem = async (newItem) => {
        const subCategoryId = getSubCategoryId(newItem.category, newItem.subCategory);
        const newInput = {
            mainCategoriesID: getMainCategoryId(newItem.category),
            subCategoriesID: subCategoryId === '' ? null : subCategoryId,
            drink: newItem.drink,
            description: newItem.description,
            price: newItem.price,
            vendorsID: userVendorSelected.id,
            type: 'menuItem'
        }
        console.log('newInput', newInput);
        try {
            const addedMenuItem = await API.graphql(graphqlOperation(createMenuItems, {input: newInput}))
            const addedItem = addedMenuItem.data.createMenuItems;
            return {
                ...addedItem, 
                "mainCategoryName": addedItem.mainCategory.name, 
                subCategoryName: addedItem.subCategory?.name ? addedItem.subCategory.name : ''
            }
        } catch (e) {
            console.log('error creating menu item', e);
            return {}
        }
    }

    const getMainCategoryId = (catName) => {
        const foundCat = categories.find(cat => cat.name.toLowerCase() === catName.toLowerCase());
        return foundCat ? foundCat.id : '';
    }

    const getSubCategoryId = (catName, subCatName) => {
        const foundCat = categories.find(cat => cat.name.toLowerCase() === catName.toLowerCase());
        if (foundCat) {
            const foundSubCat = foundCat.subCategories.items.find(subCat => subCat.name.toLowerCase() === subCatName.toLowerCase());
            return foundSubCat ? foundSubCat.id : '';
        }
        return '';
    }

    const updateMenuItem = async (menuItem) => {
        const subCategoryId = getSubCategoryId(menuItem.category, menuItem.subCategory);
        const newInput = {
            mainCategoriesID: getMainCategoryId(menuItem.category),
            subCategoriesID: subCategoryId === '' ? null : subCategoryId,
            drink: menuItem.drink,
            description: menuItem.description,
            price: menuItem.price,
            id: menuItem.id,
        }
       
        try {
            const updatedMenuItem = await API.graphql(graphqlOperation(updateMenuItems, {input: newInput}));
            return updatedMenuItem.data.updateMenuItems;
        } catch (e) {
            console.log('error updateing menu item', e);
            return false;
        }
    }

    const handleEditMenuItemFormSubmit = async (formData) => {
        if (!formData || formData.drinkName === '') {
            setEditMenuItemModalOpen(false);
            return;
        }
        const formDataItem = {
            drink: formData.drinkName,
            description: formData.drinkDescription,
            category: formData.drinkCategory.toLowerCase(),
            subCategory: formData.drinkSubCategory ? formData.drinkSubCategory.toLowerCase() : '',
            price: formData.drinkPrice,
            vendorId: userVendorSelected.id
        }
        if (editMenuItemType === 'new') {
            const retData = await saveNewMenuItem(formDataItem);
            if (Object.keys(retData).length === 0 && retData.constructor === Object) {
                // TODO set error message here.
                return;
            }
            const newData = [...menuItemsData];
            newData.push(retData);
            setMenuItemsData(newData)
        } else {
            formDataItem.id = formData.drinkId;
            const updatedMenuItem = await updateMenuItem(formDataItem);
            if (!updatedMenuItem) {
                // TODO set error message here
                return;
            }
            const newData = [...menuItemsData];

            // find item, replace with this.
            const foundIndex = newData.findIndex(item => item.id === editingMenuItem.id);
            const parsedUpdatedwMenuItem = {
                ...updatedMenuItem,
                "mainCategoryName": updatedMenuItem.mainCategory.name ? updatedMenuItem.mainCategory.name : 'Miscellaneous',
                subCategoryName: updatedMenuItem.subCategory?.name ? updatedMenuItem.subCategory?.name : ''
            }


            newData[foundIndex] = parsedUpdatedwMenuItem
            setMenuItemsData(newData);
        }
        setEditMenuItemModalOpen(false);
    }

    const handleMenuEdit = (item) => {
        item ? setEditMenuItemType('edit') : setEditMenuItemType('new');
        setEditingMenuItem(item);
        setEditMenuItemModalOpen(true);
    }

    const saveMainCategoryToDB = async (mainCat) => {
        const newInput = {
            name: mainCat.name,
            vendorsID: userVendorSelected.id
        }
        const addedMainCategory = await API.graphql(graphqlOperation(createMainCategories, {input: newInput}))
        return addedMainCategory.data.createMainCategories;
    }

    const saveSubCategoryToDB = async (subCat) => {
        const addedSubCategory = await API.graphql(graphqlOperation(createSubCategories, {input: subCat}))
        return addedSubCategory.data.createSubCategories;
    }

    const generateCsv = (type) => {
        const titleKeys = ["drink","description","price","category", "subCategory"];
        const refinedData = [];
        refinedData.push(titleKeys);

        if (type === 'example') {
            exampleMenuItemsData.forEach(item => {
                refinedData.push([item.drink, item.description, item.price, item.mainCategoryName, item.subCategoryName])
            })
        } else {
            menuItemsData.forEach(item => {
                refinedData.push([item.drink, item.description, item.price, item.mainCategoryName, item.subCategoryName])
            })
        }

        let csvContent = ''; //Edit any columns to fit your menu and then upload the csv. \n';
        refinedData.forEach(row => {
            csvContent += row.join(',') + '\n';
        })
        return csvContent;
    };

    const handleDownloadCsv = () => {
        const csv = generateCsv()
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        const link = document.createElement('a');
        link.setAttribute('href', URL.createObjectURL(blob));
        link.setAttribute('download', 'menu-items-upload-example.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleDownloadCsvExample = () => {
        const csv = generateCsv('example')
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        const link = document.createElement('a');
        link.setAttribute('href', URL.createObjectURL(blob));
        link.setAttribute('download', 'menu-items-upload-example.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: window.location.href.includes('/menuItems') ? 200 : 600,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <div 
                            className="viewButton"
                            onClick={() => handleMenuEdit(params.row)}
                        >   
                            Edit
                        </div>
                        <div
                            className="deleteButton"
                            onClick={() => handleDelete(params.row.id)}
                        >
                            Delete
                        </div>
                    </div>
                );
            },
        },
    ];
    return (
        <>
            <div className="datatable">
                <div className="datatableTitle">
                    {menuItemLinks.menuItemTitleText}
                    <div className="dataTableTitleButtons">
                        <>
                        <div onClick={() => handleDownloadCsvExample()} className="link">
                                Download example CSV
                            </div>
                            <div onClick={() => handleDownloadCsv()} className="link">
                                Download Menu as CSV To Edit
                            </div>
                            <div onClick={() => setCsvUploadModalOpen(true)} className="link">
                                Upload CSV
                            </div>
                        </>
                        <div onClick={() => handleMenuEdit(null)} className="link">
                            Add Menu Item
                        </div>
                        <div onClick={() => navigate('/editCats')} className="link">
                            Edit/Add Sub Category
                        </div>
                    </div>
                </div>
                {!isLoading ?
                    <DataGrid
                        className="datagrid"
                        rows={menuItemsData}
                        columns={menuItemColumns.concat(actionColumn)}
                        pageSize={25}
                        rowsPerPageOptions={[25]}
                        //checkboxSelection
                    />
                    :
                    <div className='loading-gif'>
                        <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="96"
                            visible={true}
                        />
                    </div>
                }
            </div>
            {csvUploadModalOpen && 
                <UploadMenuItemCSVModal 
                    handleParseCSVUploadData={handleParseCSV} 
                    modalState={csvUploadModalOpen} 
                    handleModalClose={handleCsvUploadModalClose}
                    setPosSelected={setPosSelected}
                />
            }
            {editMenuItemModalOpen && 
                <EditMenuItemModal 
                    modalState={editMenuItemModalOpen} 
                    handleModalClose={handleEditMenuItemModalClose}
                    handleFormSubmit={handleEditMenuItemFormSubmit} 
                    editingMenuItem={editingMenuItem}
                    categories={categories}
                />
            }
            {editCategorySubCategoryModalOpen &&
                <EditCategoriesModal
                    modalState={editCategorySubCategoryModalOpen}
                    handleModalClose={handleEditCategorySubCategoryModalClose}
                    setCategories={setCategories}
                    categories={categories}
                />    
            }
        </>
    );
}

export default MenuItemDataTable;