import React, { useState } from 'react';
import styled from 'styled-components';
import TopNavBar from './topNavBar';
import Footer from './footer';
import { API, graphqlOperation } from 'aws-amplify';
import ReCAPTCHA from 'react-google-recaptcha';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import {createVendorRequestAccess} from '../../graphql/mutations';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const styles = {
  largeIcon: {
    width: 300,
    height: 300,
    textAlign: 'center',
  },
  smallIcon: {
    width: 150, // Adjust for smaller screens
    height: 150,
    textAlign: 'center',
  }
}

const showForm = false;

const RequestAccess = () => {
    //Amplify.configure(awsconfig);
    const [view, setView] = useState('requestAccess');
    const [token, setToken] = useState("");
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
    const [formData, setFormData] = useState({
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        vendorName: '',
        vendorContactPhone: '',
        vendorStreet: '',
        vendorCity: '',
        vendorState: '',
        vendorZip: '',
        state: 'active'
    });

    const isStage = window.location.href.includes('drinklink-dev');
    const recaptchaKey = isStage ? '6LfWOG0qAAAAADVJXq4lVqhOdKEV0cHpYe4W0U_j' : '6LcLD2wqAAAAAJbxZRSB_i1xnr4K12Wnm8c3N_Tv'

    const setTokenFunc = (getToken) => {
      setToken(getToken);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async(e) => {
        e.preventDefault();
        console.log('Request Access Submitted', formData);
        if (formData.contactName === '' || formData.contactEmail === '' || formData.contactPhone === '' || formData.vendorName === '' || formData.vendorContactPhone === '' || formData.vendorStreet === '' || formData.vendorCity === '' || formData.vendorState === '' || formData.vendorZip === '') {
            alert('Please fill out all fields');
            return;
        }
        if (formData.honeypot) {
          console.log('Bot detected');
          return; // Block submission if honeypot is filled
      }

        //save to database
        try {
            await API.graphql(graphqlOperation(createVendorRequestAccess, { input: formData }));
        } catch (err) {
            console.log('error submitting request: ', err)
            alert('something went wrong, please try again');
            return;
        }
        setView('submitted');
    };

    return (
        <AccessWrap>
            <TopNavBar />
            {showForm ? 
            <AccessBody>
                <FormTitle>Request Vendor Access</FormTitle>
                
                {view === 'requestAccess' ?
                  <Form onSubmit={handleSubmit}>
                  <Instructions>Fill out the form below completely (all fields required) and we will get back to you as soon as possible.</Instructions>
                  <InputWrapper>
                          <Label>Contact Name:</Label>
                          <Input type="text" name="contactName" value={formData.contactName} onChange={handleChange} required />
                      </InputWrapper>
                      <InputWrapper>
                          <Label>Contact Email:</Label>
                          <Input type="email" name="contactEmail" value={formData.contactEmail} onChange={handleChange} required />
                      </InputWrapper>
                      <InputWrapper>
                          <Label>Contact Phone:</Label>
                          <Input type="tel" name="contactPhone" value={formData.contactPhone} onChange={handleChange} required />
                      </InputWrapper>
                      <InputWrapper>
                          <Label>Location Name:</Label>
                          <Input type="text" name="vendorName" value={formData.vendorName} onChange={handleChange} required />
                      </InputWrapper>
                      <InputWrapper>
                          <Label>Location Phone:</Label>
                          <Input type="tel" name="vendorContactPhone" value={formData.vendorContactPhone} onChange={handleChange} required />
                      </InputWrapper>
                      <InputWrapper>
                          <Label>Location Street:</Label>
                          <Input type="text" name="vendorStreet" value={formData.vendorStreet} onChange={handleChange} required />
                      </InputWrapper>
                      <InputWrapper>
                          <Label>Location City:</Label>
                          <Input type="text" name="vendorCity" value={formData.vendorCity} onChange={handleChange} required />
                      </InputWrapper>
                      <InputWrapper>
                          <Label>Location State:</Label>
                          <Input type="text" name="vendorState" value={formData.vendorState} onChange={handleChange} required />
                      </InputWrapper>
                      <InputWrapper>
                          <Label>Location Zip:</Label>
                          <Input type="text" name="vendorZip" value={formData.vendorZip} onChange={handleChange} required />
                      </InputWrapper>
                      <Input type="text" name="honeypot" style={{ display: 'none' }} onChange={handleChange} />
                      <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
                        <GoogleReCaptcha
                          className="google-recaptcha-custom-class"
                          onVerify={setTokenFunc}
                          refreshReCaptcha={refreshReCaptcha}
                        />
                      </GoogleReCaptchaProvider>
                      <SubmitButton type="submit">Submit</SubmitButton>
                  </Form>
                  :
                  <SuccessMessageWrap>
                    <SuccessMessageText>Thank you for your request. We will get back to you as soon as possible.</SuccessMessageText>
                    <SuccessMessageIcon>
                      <CheckCircleOutlineIcon style={window.innerWidth < 600 ? styles.smallIcon : styles.largeIcon} />
                    </SuccessMessageIcon>
                  </SuccessMessageWrap>
            }
            </AccessBody>
            :
            <CommingSoon>
                <h1>Coming Soon</h1>
                <CommingSoonText>We are still in development and conducting trials in Bend, Oregon.  Please come back soon!!!</CommingSoonText>
            </CommingSoon>
        }
            <Footer />
        </AccessWrap>
    );
};

export default RequestAccess;

// Styled-components

const SuccessMessageWrap = styled.div`
`

const SuccessMessageText = styled.p`
  margin: 20px;

  @media (max-width: 600px) {
        padding: 10px;
        margin-top: 0px;
        font-size: 14px;
  }
`
const SuccessMessageIcon = styled.div`
  height: auto;
  margin: 20px;
  padding: 20px;
  justify-content: center;
  flexDirection: row;
  display: flex;
  background-color: #3f704d;
  border-radius: 10px;
  color: white;

  @media (max-width: 600px) {
        padding: 10px;
        font-size: 14px;
        justify-content: center;
    }
`

const AccessWrap = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;

    @media (max-width: 600px) {
        padding: 10px;
        margin: 0px;
        justify-content: top;
    }
`;

const AccessBody = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;

    @media (max-width: 600px) {
        justify-content: flex-start;
        padding: 10px;
        margin: 0px;
    }
`;
const CommingSoon = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    font-size: 24px;
    color: #333;
    margin: 20px;

    @media (max-width: 600px) {
        justify-content: flex-start;
        padding: 10px;
        margin: 10px;
    }
`;

const CommingSoonText = styled.div``;


const Instructions = styled.div`
    margin: 10px auto 20px;
    color: #333;
  `

const FormTitle = styled.h2`
    margin-bottom: 20px;
    color: #333;
    margin-top: 30px;

    @media (max-width: 600px) {
        margin-top: 10px;
        padding: 0px;
    }
`;

const Form = styled.form`
    background: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
`;

const InputWrapper = styled.div`
    margin-bottom: 20px;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 8px;
    color: #555;
    font-size: 14px;
`;

const Input = styled.input`
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
`;

const SubmitButton = styled.button`
    width: 100%;
    padding: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;

    &:hover {
        background-color: #0056b3;
    }
`;