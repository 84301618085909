import * as React from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import "./landing.scss";
import backgroundImage from '../../assets/splash-entry-image.png';
import TopNavBar from './topNavBar';
import Footer from './footer';
import { AuthContext } from '../../context/AuthContext';

const LandingPage = () => {
    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();

    return (
        <div className="landing-wrap">
            <TopNavBar />
            <div className="landing-body">
                <div 
                    className="landing-bannerImage" 
                    style={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        overflow: 'hidden'
                        
                    }}
                >
                    <div className="landing-title">
                        DrinkLink
                    </div>
                    <div className="landing-description">
                        Send Some Cheer!
                    </div>
                    <div className="app-download-links">
                        <div className="play-store-image-link">
                            <a href='https://play.google.com/store/apps/details?id=com.drinklink.dev&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                <img alt='Get it on Google Play' width='250px' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                            </a>
                        </div>
                        <div className="play-store-image-link">
                            <a href='https://apps.apple.com/us/app/drink-link/id6447276939'>
                                <img alt='Get it on Google Play' width='200px' src={require('../../assets/Download_on_the_App_Store_Badge_US-UK_blk_092917.png')}/>
                                {/* <img src={require('../../assets/src/assets/Download_on_the_App_Store_Badge_US-UK_blk_092917.png')} alt="app home page screen shot"/> */}
                            </a>
                        </div>
                    </div>
                </div>
                
                
                <div className="landing-descriptive-container">
                    <div className="landing-descriptive-wrap">
                        <div className="landing-descriptive-body-wrap">
                            <div className="landing-descriptive-body-title">
                                Cheers!
                            </div>
                            <div className="landing-descriptive-body-subhead">
                                Welcome to <span className="make-italic">
                                    DrinkLink
                                </span>
                            </div>
                            <div className="landing-descriptive-body-text">
                                Buying a friend, co-worker or colleague a drink is the best way to say 'Thanks', 'I appreciate you', or 'congratulations'.  You select it, we will send it and they go to the bar to redeem it.
                            </div>
                            <div className="landing-descriptive-body-text">
                                If you're 21 or older, download the app to get started sending cheer.  If not, we will see you on your 21st birthday.
                            </div>
                            <div className="landing-descriptive-body-text">
                                Drink responsibly.  Give as much as you want!
                            </div>
                            <div className="app-download-links-left">
                                <div className="play-store-image-link">
                                    <a href='https://play.google.com/store/apps/details?id=com.drinklink.dev&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                        <img alt='Get it on Google Play' width='250px' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                                    </a>
                                </div>
                                <div className="play-store-image-link">
                                    <a href='https://apps.apple.com/us/app/drink-link/id6447276939'>
                                        <img alt='Get it on Google Play' width='200px' src={require('../../assets/Download_on_the_App_Store_Badge_US-UK_blk_092917.png')}/>
                                        {/* <img src={require('../../assets/src/assets/Download_on_the_App_Store_Badge_US-UK_blk_092917.png')} alt="app home page screen shot"/> */}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="landing-descriptive-body-image">
                            <img src={require('../../assets/screenShotHomeFF.png')} alt="app home page screen shot"/>
                        </div>
                    </div>
                </div>
                
                <div className="landing-steps-container">
                    <div className="landing-steps-title">
                        As easy as one... two... three!
                    </div>
                    <div className="landing-steps-steps-container">
                        <div className="landing-steps-steps">
                            <div className="landing-steps-item">
                                <div className="landing-steps-item-title-small">
                                    <div className="landing-steps-item-icon">
                                        <img src={require('../../assets/1-xxl.png')} alt="step 1"/>
                                    </div>
                                
                                    <div className="landing-steps-item-title">
                                        Buy Drink
                                    </div>
                                </div>
                                <div className="landing-steps-item-description">
                                    Find your friends, search vendors, select drink and purchase
                                </div>
                            </div>
                            <div className="landing-steps-item">
                                <div className="landing-steps-item-title-small">
                                    <div className="landing-steps-item-icon">
                                        <img src={require('../../assets/2-xxl.png')} alt="step 2"/>
                                    </div>
                                
                                    <div className="landing-steps-item-title">
                                        Friend is Notified
                                    </div>
                                </div>
                                <div className="landing-steps-item-description">
                                    Your friend will be notified of drink for them from you.  They can view the drink in the app.
                                </div>
                            </div>
                            <div className="landing-steps-item">
                                <div className="landing-steps-item-title-small">
                                    <div className="landing-steps-item-icon">
                                        <img src={require('../../assets/3-xxl.png')} alt="step 3"/>
                                    </div>
                                    <div className="landing-steps-item-title">
                                        Redeem Drink
                                    </div>
                                </div>
                                <div className="landing-steps-item-description">
                                    Friend selects redeem drink in app.  Tell the bartender at the location the drink is for that you have a Drinklink drink. They will tell them to send it to the bar and then they will receive thier drink and enjoy!
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="landing-descriptive-container">
                    <div className="landing-descriptive-wrap">
                        <div className="landing-vendor-body-image">
                            <img src={require('../../assets/screenShotBar.png')} alt="app home page screen shot"/>
                        </div>
                        <div className="landing-descriptive-body-wrap">
                            <div className="landing-descriptive-body-title">
                                Attention Vendors!
                            </div>
                            <div className="landing-descriptive-body-subhead">
                                Get set up to show in vendor search
                            </div>
                            <div className="landing-descriptive-body-text">
                                Reach thousands of customers by simply joining drinklink.
                            </div>
                            <div className="landing-descriptive-body-text">
                                Users will be able to see your location listed within that city, purchase drinks for friends and provide revenue for you.
                            </div>
                            <div className="landing-vendor-body-image-mobile">
                                <img src={require('../../assets/screenShotBar.png')} alt="app home page screen shot"/>
                            </div>
                            <div className="landing-descriptive-body-text">
                                You only have to request access, set up your location and menu.  We will provide you with the app for redeeming drinks on your end - simply have customers send the drink to the bar and you serve it up.  You can view reports of all drinks purchased and redeemed right here on the site.
                            </div>
                            <div className="landing-action-button" onClick={() => navigate('/requestAccess')}>
                                Request Vendor Access
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default LandingPage;