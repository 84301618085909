import * as React from 'react'
import {NumericFormat} from 'react-number-format';
import {formatMoney} from '../../utils/utils';

export const vendorLinks = {

}

export const vendorColumns = [
    {
        field: "vendorName",
        headerName: "Name",
        width: 200
    },
    {
        field: "vendorStreet",
        headerName: "Street",
        widht: 300
    },
    {
        field: "vendorCity",
        headerName: "City",
        width: 100
    },
    {
        field: "vendorState",
        headerName: "State",
        width: 40
    },
    {
        field: "vendorZip",
        headerName: "Zip",
        width: 130
    },
    {
        field: "waveDrinklinkFee",
        headerName: "Wave Fee",
        width: 100
    },
    {
        field: "feeSchedule",
        headerName: "Fee",
        width: 100,
        renderCell: (params) => {
            return (
                <div>
                    {params.row.feeSchedule ? <NumericFormat value={formatMoney(params.row.feeSchedule.fee).toString()} displayType={'text'} thousandSeparator={true} prefix={'$'} /> : "Default"}
                </div>
            );
        },
    },
    {
        field: "status",
        headerName: "DL Status",
        width: 80
    },
    {
        field: "vendorDisplayStatus",
        headerName: "Live status",
        width: 130
    }

]