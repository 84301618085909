import "./vendors-owed-datatable.scss";
import * as React from 'react';
import { DataGrid } from "@mui/x-data-grid";
import { Storage } from 'aws-amplify';
import styled from 'styled-components';
import { useEffect, useState } from "react";
import {API, graphqlOperation} from "aws-amplify";
import { RotatingLines } from 'react-loader-spinner';
import {drinkColumns} from './vendorPaymentsDatatableSource';
import {listVendorPaymentsWithData} from "../../graphql/customQueries";

const VendorsOwedListWrap = styled.div`
    font-size: 24px;
    margin: 30px 30px;
    display: flex;
    flex-direction: column;
    border: 1px solid grey;
    border-radius: 10px;
`
const VendorWrap = styled.div`
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
`
const SelectVendorButton = styled.div`
    margin-left: 30px;
    padding: 2px 5px;
    border-radius: 5px;
    color: darkblue;
    border: 1px dotted rgba(0, 0, 139, 0.596);
    cursor: pointer;
`
const VendorAddressWrap = styled.div`
    margin-left: 20px;
    font-size: 18px;
`
const ActionButtonsWrap = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`
const DrinkTotalSection = styled.div`
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
`

const DrinklinkAdminVendorsPaidDatatable = () => {
    const [data, setData] = useState([]);
    const [vendorPayments, setVendorPayments] = useState([])
    const [vendorDetailsVendor, setVendorDetailsVendor] = useState()
    const [paidVendors, setPaidVendors] = useState([])
    const [vendorPayment, setVendorPayment] = useState()
    const [view, setView] = useState('vendors')
    const [isLoading, setIsLoading] = useState(true);
    const [totalOwed, setTotalOwed] = useState()
    const [pdfUrl, setPdfUrl] = useState()

    const fetchVendorPayments = async () => {
        const vendorPaymentsReturn = await API.graphql(graphqlOperation(listVendorPaymentsWithData))
        const vendorPayments = vendorPaymentsReturn.data.listVendorPayments.items
        aggregateVendors(vendorPayments);
        setVendorPayments(vendorPayments)
        setIsLoading(false)
    }

    useEffect(() => {
        const fetchData = async () => {
          fetchVendorPayments()
        }
        fetchData()
    }, [])

    const setPDFUrl = async () => {
        const url = await Storage.get(vendorPayment.invoiceURL); 
        setPdfUrl(url)
    }

    useEffect(() => {
        if (view === 'pdfView') {
            setPDFUrl()
        }
    }, [view])

    const aggregateVendors = (vendorPayments) => {
        let vendorsIdList = []
        let theVendors = []
        for (const item of vendorPayments) {
            const found = vendorsIdList.find((itm) => itm === item.vendor.id)
            if (!found) {
                theVendors.push(item.vendor)
                vendorsIdList.push(item.vendor.id);
            }
        }
        setPaidVendors(theVendors)
    }

    const handleShowVendorDetails = (vendor) => {
        //load drinks for this vendor into data
        const vendorPaymentsList = []
        let runningTotal = 0;
        for (const pay of vendorPayments) {
            if (pay.vendor.id === vendor.id) {
              vendorPaymentsList.push(pay)
                runningTotal = runningTotal + pay.amount
            }
        }
        setTotalOwed(runningTotal)
        setData(vendorPaymentsList)
        setVendorDetailsVendor(vendor);
        setView('details')
    }

    const displayVendor = (vendor) => {
        return (
            <VendorWrap>
                <div>{vendor.vendorName}</div>
                <VendorAddressWrap>
                    <div>{vendor.vendorCity}, {vendor.vendorState}</div>
                </VendorAddressWrap>
                <SelectVendorButton
                    onClick={() => handleShowVendorDetails(vendor)}
                >View Details</SelectVendorButton>
            </VendorWrap>
        )
    }

    const dispalyVendorsList = () => {
        return (
            <VendorsOwedListWrap>
                {paidVendors && paidVendors.length > 0 ? paidVendors.map((vend) => displayVendor(vend)) : <div>no paid vendors</div>}
            </VendorsOwedListWrap>
        )
    }

    const onBackToVendors = () => { 
        setView('vendors');
    }

    const onBackToDetails = () => {
        setView('details');
    }

    const handleViewInvoice = (row) => {
        setVendorPayment(row)
        setView('pdfView')
    }

    const actionColumn = [
      {
          field: "action",
          headerName: "Action",
          width: window.location.href.includes('/menuItems') ? 200 : 600,
          renderCell: (params) => {
              return (
                  <div className="cellAction">
                      <div 
                          className="viewButton"
                          onClick={() => handleViewInvoice(params.row)}
                      >   
                          View Invoice
                      </div>
                  </div>
              );
          },
      },
  ];

    const displayPDFView = () => {
        return (
            <div className="pdf-viewer-page">
                <div className="datatableTitle">
                    <ActionButtonsWrap>
                        <div className="dataTableTitleButtons">
                            <div onClick={() => onBackToDetails()} className="link">
                                Back to Vendors
                            </div>
                        </div>
                    </ActionButtonsWrap>
                    </div>
                    {pdfUrl ?
                            <iframe title='mypdf' src={pdfUrl} width="100%" height="600px" />
                    :
                    <div>loading...</div>
                    }
                
            </div>
        )
    }

    const returnView = () => {
        if (view === 'vendors') {
            return dispalyVendorsList()
        } if (view === 'pdfView') {
            return displayPDFView()
        } else {
            return (
                <>
                    <DrinkTotalSection>Drinklink has paid {vendorDetailsVendor.vendorName}: ${totalOwed}</DrinkTotalSection>
                    <DataGrid
                        className="datagrid"
                        rows={data}
                        columns={drinkColumns.concat(actionColumn)}
                        pageSize={9}
                        rowsPerPageOptions={[25]}
                        //checkboxSelection
                    />
                </>
            )
        }
    }

    return (
        <>
            <div className="datatable">
                <div className="datatableTitle">
                    Vendors Paid
                    {view === 'details' && <ActionButtonsWrap>
                        <div className="dataTableTitleButtons">
                            <div onClick={() => setView('vendors')} className="link">
                                Back to Vendors
                            </div>
                        </div>
                    </ActionButtonsWrap>}
                    <div className="dataTableSearch">
                        {/* <Searchbar
                            style={{fontSize: 20}}
                            icon="account-search"
                            placeholder={'Enter name or email'}
                            value={searchText}
                            onChangeText={(txt) => {
                                onChangeSearchText(txt)
                            }}
                        /> */}
                    </div>
                </div>
                {!isLoading ?
                    <>
                        {returnView()}
                    </>
                    
                    :
                    <div className='loading-gif'>
                        <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="96"
                            visible={true}
                        />
                    </div>
                }
            </div>
        </>
    );
}

export default DrinklinkAdminVendorsPaidDatatable