import "./vendors-owed-datatable.scss";
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from "@mui/x-data-grid";
import { PDFViewer, pdf } from '@react-pdf/renderer';
import { Storage } from 'aws-amplify';
import styled from 'styled-components';
import { useEffect, useState, useContext } from "react";
import {API, graphqlOperation} from "aws-amplify";
import { AuthContext } from "../../context/AuthContext";
import { RotatingLines } from 'react-loader-spinner';
import {
    listMenuItemsPurchasedsWithUsers
} from "../../../src/graphql/customQueries"
import {drinkColumns} from './vendorsOwedDatatableSource'
import {updateMenuItemsPurchased, createVendorPayments} from "../../graphql/mutations";
import {formatMoney} from '../../utils/utils';
import Invoice from '../PdfPages/invoice';
import CreateInvoiceModal from './CreateInvoiceModal';

const VendorsOwedListWrap = styled.div`
    font-size: 24px;
    margin: 30px 30px;
    display: flex;
    flex-direction: column;
    border: 1px solid grey;
    border-radius: 10px;
`
const VendorWrap = styled.div`
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
`
const SelectVendorButton = styled.div`
    margin-left: 30px;
    padding: 2px 5px;
    border-radius: 5px;
    color: darkblue;
    border: 1px dotted rgba(0, 0, 139, 0.596);
    cursor: pointer;
`
const VendorAddressWrap = styled.div`
    margin-left: 20px;
    font-size: 18px;
`
const ActionButtonsWrap = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`
const DrinkTotalSection = styled.div`
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
`

const DrinklinkAdminVendorsOwedDatatable = () => {
    const [data, setData] = useState([]);
    const [drinkData, setDrinkData] = useState([])
    const [vendorDetailsVendor, setVendorDetailsVendor] = useState()
    const [owedVendors, setOwedVendors] = useState([])
    const [formattedInvoiceData, setFormattedInvoiceData] = useState({})
    const [view, setView] = useState('vendors')
    const [fileName, setFileName] = useState('')
    const [isLoading, setIsLoading] = useState(true);
    const [totalOwed, setTotalOwed] = useState()
    const [uploadStatus, setUploadStatus] = useState('');
    const [invoiceSaved, setInvoiceSaved] = useState(false);
    const [createInvoiceModalOpen, setCreateInvoiceModalOpen] = useState(false);

    const {currentUser} = useContext(AuthContext);
    const navigate = useNavigate();

    const handleInvoiceModalClose = () => setCreateInvoiceModalOpen(false);

    const fetchTheData = async () => {  
        // get all drinks in redeemed state.
        const menuItemsPurchasedReturn = await API.graphql(graphqlOperation(
            listMenuItemsPurchasedsWithUsers, {
                filter: {
                    state: {eq: 'redeemed'}
                }
            }
        ))
        let menuItemsPurchased = menuItemsPurchasedReturn.data.listMenuItemsPurchaseds.items
        console.log('menuItemsPurchased', menuItemsPurchased);
        aggregateVendors(menuItemsPurchased);
        setDrinkData(menuItemsPurchased);
        console.log('menuItemsPurchased', menuItemsPurchased);
        // aggregate that data by vendorsID
        // set vendors as the data
        setIsLoading(false)
    }

    useEffect(() => {
        const fetchData = async () => {
            fetchTheData()
        }
        fetchData()
    }, [])

    const aggregateVendors = (menuItemsPurchased) => {
        let vendorsIdList = []
        let theVendors = []
        for (const item of menuItemsPurchased) {
            const found = vendorsIdList.find((itm) => itm === item.vendor.id)
            if (!found) {
                theVendors.push(item.vendor)
                vendorsIdList.push(item.vendor.id);
            }
        }
        setOwedVendors(theVendors)
    }

    const handleShowVendorDetails = (vendor) => {
        // load drinks for this vendor into data
        const vendorDrinks = []
        let runningTotal = 0;
        for (const dr of drinkData) {
            if (dr.vendor.id === vendor.id) {
                vendorDrinks.push(dr)
                runningTotal = runningTotal + dr.drinkCost + dr.tip
            }
        }
        setTotalOwed(runningTotal)
        setData(vendorDrinks)
        setVendorDetailsVendor(vendor);
        setView('details')
    }

    const displayVendor = (vendor) => {
        console.log('vendor', vendor);
        return (
            <VendorWrap key={vendor.id}>
                <div>{vendor.vendorName}</div>
                <VendorAddressWrap>
                    <div>{vendor.vendorCity}, {vendor.vendorState}</div>
                </VendorAddressWrap>
                <SelectVendorButton
                    onClick={() => handleShowVendorDetails(vendor)}
                >View Details</SelectVendorButton>
            </VendorWrap>
        )
    }

    const dispalyVendorsList = () => {
        return (
            <VendorsOwedListWrap>
                {owedVendors && owedVendors.length > 0 ? owedVendors.map((vend) => displayVendor(vend)) : <div>no owed vendors</div>}
            </VendorsOwedListWrap>
        )
    }

    const updateMultipleDrinks = async (vendorPaymentId) => {
        // set state of drinks to vendor-paid and add vendorPaymentId to each drink
        console.log('data', data)
        const mutationPromises = data.map((drink) => {
            const drinkStateHistory = JSON.parse(drink.stateHistory);
            drinkStateHistory.push({ drink_status: 'vendor-paid', date: new Date().getTime() });
        
            const input = {
                id: drink.id, 
                stateHistory: JSON.stringify(drinkStateHistory), 
                state: 'vendor-paid',
                vendorPaymentID: vendorPaymentId
            };
        
            console.log('input', input);
        
            return API.graphql(graphqlOperation(updateMenuItemsPurchased, { input }))
                .then((response) => {
                    console.log('updated item', response.data.updateMenuItemsPurchased);
                })
                .catch((e) => {
                    console.log('error updating item', e);
                    throw e; // rethrow to ensure Promise.all fails if any request fails
                });
        });
        return await Promise.all(mutationPromises)
    }

    const formatDataForInvoce = (paymentType, paymentNumber) => {
        const invoiceDrinkData = [];
        let tipTotal = 0;
        let costTotal = 0;
        let priceTotal = 0;
        data.forEach(drink => {
            const theDrink = { ...drink };
            const redeemDate = JSON.parse(theDrink.stateHistory).find((item) => item.drink_status === 'redeemed').date;
            const theDate = new Date(parseInt(redeemDate));
            let period = theDate.getHours() >= 12 ? 'PM' : 'AM';
            const hours = theDate.getHours() > 12 ? theDate.getHours() - 12 : theDate.getHours();
            const formatDate = `${theDate.getMonth() + 1}-${theDate.getDate()} ${hours}:${theDate.getMinutes()} ${period}`;
            const drinkData = {
                item: theDrink.menuItemName,
                id: theDrink.id,
                dateRedeemed: formatDate,
                cost: formatMoney(theDrink.drinkCost),
                tip: formatMoney(theDrink.tip),
                amount: formatMoney(theDrink.drinkCost + theDrink.tip)
            }
            tipTotal = tipTotal + theDrink.tip;
            costTotal = costTotal + theDrink.drinkCost;
            priceTotal = priceTotal + theDrink.drinkCost + theDrink.tip;
            invoiceDrinkData.push(drinkData);
        });
        const today = new Date();
        var randomEnd = Math.floor(1000 + Math.random() * 9000);
        const theDate = `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`;
        const theMonth = today.getMonth().toString().padStart(2, '0');
        const theDay = today.getDate().toString().padStart(2, '0');
        const invoiceNumer = `${today.getFullYear()}${theMonth}${theDay}-${randomEnd}`
        console.log('vendorDetailsVendor', vendorDetailsVendor)
        const invoiceData = {
            vendorAddress: `${vendorDetailsVendor.vendorStreet ? vendorDetailsVendor.vendorStreet : ''}, ${vendorDetailsVendor.vendorCity}, ${vendorDetailsVendor.vendorState} ${vendorDetailsVendor.vendorZip}`,
            date: theDate,
            drinks: invoiceDrinkData,
            tipTotal: formatMoney(tipTotal),
            costTotal: formatMoney(costTotal),
            priceTotal: formatMoney(priceTotal),
            invoiceNumber: invoiceNumer,
            paidBy: `${paymentType} ${paymentNumber}`
        }
        setFormattedInvoiceData(invoiceData)
    }

    const generatePdfBlob = async () => {
        const document = <Invoice invoiceData={formattedInvoiceData} />;
        
        // Generate a Blob from the PDF
        const pdfBlob = await pdf(document).toBlob();
        return pdfBlob;
      };
    
    const handleUpload = async () => {
        let result;
        try {
            const pdfBlob = await generatePdfBlob();
        
            // Upload the Blob to S3
            result = await Storage.put(fileName, pdfBlob, {
                contentType: 'application/pdf',
            });

            setUploadStatus('File uploaded successfully!');
            //url = await Storage.get(result.key);  // Retrieve the URL
            setInvoiceSaved(true);
        } catch (error) {
            console.error('Error uploading file:', error);
            setUploadStatus('Failed to upload the file.');
        }
        return result.key
    };

    const handleSaveInvoiceAndMarkDrinksPaid = async () => {
        setIsLoading(true);
        const theUrl = await handleUpload();
        console.log('theUrl', theUrl)
        const vendorPayment = {
            amount: parseFloat(formattedInvoiceData.priceTotal).toFixed(2),
            paymentDate: formattedInvoiceData.date,
            vendorsID: vendorDetailsVendor.id,
            adminUserID: currentUser.id,
            invoiceNumber: formattedInvoiceData.invoiceNumber,
            invoiceURL: theUrl,
            paidBy: formattedInvoiceData.paidBy,
        }

        console.log('vendorPayment', vendorPayment)

        try {
            const savedVendorPayment = await API.graphql(graphqlOperation(createVendorPayments, {input: vendorPayment}))
            console.log('savedVendorPayment', savedVendorPayment)
            
            if (savedVendorPayment) {
                //set all drinks in data to paid
                await updateMultipleDrinks(savedVendorPayment.data.createVendorPayments.id)
                    .then((updatedItems) => {
                    console.log('updatedItems', updatedItems)
                    // export data to csv
                    })
                    .catch(e => {
                        console.log('error updating items', e)
                    }
                )
            }
        } catch (e) {
            console.log('error saving vendorPayment', e)
        }
        //await fetchTheData();
        setView('vendors');
        setIsLoading(false);
        navigate('/drinklinkAdmin-vendorsOwed')
    }

    const handleInvoiceModalFormSubmit = (formData) => {
        console.log('formData', formData)
        setCreateInvoiceModalOpen(false);
        onCreateInvoice(formData.paymentType, formData.paymentNumber);
    }

    const onCreateInvoice = (paymentType, paymentNumber) => {
        const thisFileName = `invoice-${Date.now()}.pdf`;
        setFileName(thisFileName);
        formatDataForInvoce(paymentType, paymentNumber);
        setView('pdfView');
    }

    const onBackToVendors = () => { 
        setCreateInvoiceModalOpen(false);
        setView('vendors');
    }

    const displayPDFView = () => {  
        return (
            <div className="pdf-viewer-page">
                <div className="datatableTitle">
                <ActionButtonsWrap>
                    <div className="dataTableTitleButtons">
                        <div onClick={() => onBackToVendors()} className="link">
                            Back to Vendors
                        </div>
                    </div>
                    <div className="dataTableTitleButtons">
                        <div onClick={() => invoiceSaved ? alert('already saved') : handleSaveInvoiceAndMarkDrinksPaid()} className="link">
                            {invoiceSaved ? 'Invoice Saved' : 'Save Invoice and Mark Drinks Paid'}
                        </div>
                    </div>
                </ActionButtonsWrap>
                </div>
                <p>{uploadStatus}</p>
                <PDFViewer width="1000" height="650" className="app">
                    <Invoice invoiceData={formattedInvoiceData}/>
                </PDFViewer>
            </div>
        )
    }

    const returnView = () => {
        if (view === 'vendors') {
            return dispalyVendorsList()
        } if (view === 'pdfView') {
            return displayPDFView()
        } else {
            return (
                <>
                    <DrinkTotalSection>Drinklink owes {vendorDetailsVendor.vendorName}: ${totalOwed}</DrinkTotalSection>
                    <DataGrid
                        className="datagrid"
                        rows={data}
                        columns={drinkColumns}
                        pageSize={9}
                        rowsPerPageOptions={[25]}
                        //checkboxSelection
                    />
                </>
            )
        }
    }

    console.log('currentUser', currentUser)

    return (
        <>
            <div className="datatable">
                <div className="datatableTitle">
                    Vendors Owed
                    {view === 'details' && <ActionButtonsWrap>
                        <div className="dataTableTitleButtons">
                            <div onClick={() => setView('vendors')} className="link">
                                Back to Vendors
                            </div>
                        </div>
                        <div className="dataTableTitleButtons">
                            <div onClick={() => setCreateInvoiceModalOpen(true)} className="link">
                                Create Invoice and Pay Vendor
                            </div>
                        </div>
                    </ActionButtonsWrap>}
                    {/* { userVendorSelected.role !== 'viewer' && 
                        <div className="dataTableTitleButtons">
                            <div onClick={() => handleAddUser(null, 'add-user')} className="link">
                                Add New User
                            </div>
                        </div>
                    } */}
                    <div className="dataTableSearch">
                        {/* <Searchbar
                            style={{fontSize: 20}}
                            icon="account-search"
                            placeholder={'Enter name or email'}
                            value={searchText}
                            onChangeText={(txt) => {
                                onChangeSearchText(txt)
                            }}
                        /> */}
                    </div>
                </div>
                {!isLoading ?
                    <>
                        {returnView()}
                    </>
                    
                    :
                    <div className='loading-gif'>
                        <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="96"
                            visible={true}
                        />
                    </div>
                }
            </div>
            { createInvoiceModalOpen &&
                <CreateInvoiceModal
                    modalState={createInvoiceModalOpen}
                    handleModalClose={handleInvoiceModalClose}
                    handleFormSubmit={handleInvoiceModalFormSubmit}
                />
            }
        </>
    );
}

export default DrinklinkAdminVendorsOwedDatatable