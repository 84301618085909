import React, { useEffect } from 'react';

const AppRedirectPage = () => {
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.drinklink.dev';
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = 'https://apps.apple.com/us/app/drink-link/id6447276939';
    } else {
      // Optional: For desktop users, show a page with links to both stores
      window.location.href = '/choose-your-app-store';
    }
  }, []);

  return (
    <div>
      <p>Redirecting you to the appropriate app store...</p>
    </div>
  );
};

export default AppRedirectPage;