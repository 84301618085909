export const listMainCategories = /* GraphQL */ `
  query ListMainCategories(
    $filter: ModelMainCategoriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMainCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        MenuItems {
          nextToken
        }
        name
        vendorsID
        subCategories {
          items {
            id
            name
            mainCategoriesID
            mainCategory {
              id
              name
            }
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listMenuItemsPurchasedsWithUsers = /* GraphQL */ `
  query ListMenuItemsPurchaseds(
    $filter: ModelMenuItemsPurchasedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMenuItemsPurchaseds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        category
        personalMessage
        state
        stateHistory
        stripeStatus
        stripeStatusHistory
        stripeReceiptUrl
        drinkCost
        fees
        menuItemDescription
        menuItemName
        stripePaymentIntentID
        tip
        purchaserID
        receiverID
        purchaser {
          id
          fName  # Include fields from the Users model you want to fetch
          lName
          email
          # Add any other fields you need from the Users type
        }
        receiver {
          id
          fName  # Include fields from the Users model you want to fetch
          lName
          email
          # Add any other fields you need from the Users type
        }
        vendorsID
        vendor {
          id
          vendorName
          vendorStreet
          vendorCity
          vendorState
          vendorZip
          vendorImage
        }
        vendorNotes
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const menuItemsByDrinkPlusData = /* GraphQL */ `
  query MenuItemsByDrink(
    $type: String!
    $drink: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMenuItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    menuItemsByDrink(
      type: $type
      drink: $drink
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        drink
        price
        vendorsID
        mainCategoriesID
        mainCategory {
          id
          name}
        subCategoriesID
        subCategory {
          id
          name
          mainCategoriesID
        }
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listVendorsAndFees = /* GraphQL */ `
  query ListVendors(
    $filter: ModelVendorsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVendors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        vendorDisplayStatus
        vendorCity
        vendorContactPhone
        vendorEmail
        vendorImage
        vendorName
        vendorState
        vendorStreet
        vendorZip
        waveDrinklinkFee
        feeScheduleID
        feeSchedule {
          id
          fee
          name
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listVendorUsersWithUserData = /* GraphQL */ `
  query ListVendorUsers(
    $filter: ModelVendorUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVendorUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lastAccess
        role
        status
        vendorsID
        usersID
        users {
          id
          fName
          lName
          email
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listDrinklinkAdminsWithUsers = /* GraphQL */ `
  query ListDrinklinkAdmins(
    $filter: ModelDrinklinkAdminsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDrinklinkAdmins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lastAccess
        role
        status
        usersID
        users {
          id
          fName
          lName
          email
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listVendorUsersWithVendorData = /* GraphQL */ `
  query ListVendorUsers(
    $filter: ModelVendorUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVendorUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lastAccess
        role
        status
        vendorsID
        vendors {
          id
          status
          vendorDisplayStatus
          vendorName
          vendorCity
          vendorState
          vendorStreet
          vendorZip
          vendorImage
          vendorContactPhone
          vendorEmail
          waveDrinklinkFee
        }
        usersID
        users {
          id
          fName
          lName
          email
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const menuItemsByDrinkAllData = /* GraphQL */ `
  query MenuItemsByDrink(
    $type: String!
    $drink: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMenuItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    menuItemsByDrink(
      type: $type
      drink: $drink
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        drink
        price
        vendorsID
        mainCategoriesID
        mainCategory {
          id
          name
          vendorsID
        }
        subCategoriesID
        subCategory {
          id
          name
          mainCategoriesID
        }
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listMenuItemsSentToBarsWithData = /* GraphQL */ `
  query ListMenuItemsSentToBars(
    $filter: ModelMenuItemsSentToBarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMenuItemsSentToBars(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        receiverName
        id
        itemID
        item {
          id
          category
          personalMessage
          state
          stateHistory
          stripeStatus
          stripeStatusHistory
          stripeReceiptUrl
          drinkCost
          fees
          menuItemDescription
          menuItemName
          stripePaymentIntentID
          tip
          purchaserID
          purchaser { 
            id
            fName
            lName
            email
          }
          receiverID
          vendorsID
          vendorNotes
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listVendorPaymentsWithData = /* GraphQL */ `
  query ListVendorPayments(
    $filter: ModelVendorPaymentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVendorPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        amount
        paymentDate
        vendorsID
        vendor {
          id
          vendorName
          vendorCity
          vendorState
          vendorZip
          vendorImage
          vendorContactPhone
          vendorEmail
          waveDrinklinkFee
        }
        adminUserID
        adminUser {
          id
          fName
          lName
          email
        }
        paidBy
        invoiceNumber
        invoiceURL
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

