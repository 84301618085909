import * as React from 'react';
import Sidebar from "../../components/sidebar/Sidebar";
import TopNavBar from "../Landing/topNavBar";
import Footer from "../Landing/footer";
//import DrinklinkAdminVendorsPaidDatatable from './drinklink-admin-vendors-paid';
import PaymentsReceivedDatatable from './PaymentsReceivedDatatable';
const PaymentsReceived = () => {
    return (
        <>
          <TopNavBar />
            <div className="list">
            <Sidebar/>
                <div className="listContainer">
                  <PaymentsReceivedDatatable />
                </div>
            </div>
          <Footer />
        </>
      )
}

export default PaymentsReceived