import * as React from 'react';
import { useState, useEffect } from "react";
import Modal from '@mui/material/Modal';
import styled from 'styled-components';
import "./edit-menu-item-modal.scss";
import Select from 'react-select'

const SaveMenuItemButton = styled.button`
    justify-content: center;
    align-items: center;
    padding: 0px;
    border-radius: 6px;
`;

const SaveMenuItemsButtonsRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
`;

const MustSelectLiquorText = styled.div`
    font-size: 12px;
    color: #999;
`

const EditMenuItemModal = ({modalState, handleModalClose, handleFormSubmit, editingMenuItem, categories}) => {
    const [formData, setFormData] = useState({
        drinkId: editingMenuItem ? editingMenuItem.id : '',
        drinkName: editingMenuItem ? editingMenuItem.drink : '',
        drinkDescription: editingMenuItem ? editingMenuItem.description : '',
        drinkCategory: editingMenuItem?.mainCategory ? editingMenuItem.mainCategory.name : '',
        drinkSubCategory: editingMenuItem?.subCategory ? editingMenuItem.subCategory.name : '',
        drinkPrice: editingMenuItem ? editingMenuItem.price : ''
    });
    const [errorMessage, setErrorMessage] = useState([]);
    const [categoryData, setCategoryData] = useState([])

    useEffect(() => {
        const getCategoryOptions = () => {
            let formattedCategories = []
            for(const cat of categories) {
                const newCat = {
                    value: cat.name,
                    label: cat.name,
                    //id: cat.id
                }
                formattedCategories.push(newCat)
            }
            return formattedCategories
        }

        const formattedCategoryOptions = getCategoryOptions();
        setCategoryData(formattedCategoryOptions)
    }, [categories])

    const handleSaveMenuItem = (event) => {
        if (formData.drinkCategory === '') {
            const newMessages = [...errorMessage];
            newMessages.push('Please select a category');
            setErrorMessage(newMessages);
            return;
        } else if (formData.drinkName === '') {
            const newMessages = [...errorMessage];
            newMessages.push('Drink name can not be empty');
            setErrorMessage(newMessages);
        } else if (formData.drinkPrice === '') {
            const newMessages = [...errorMessage];
            newMessages.push('Drink must have a price. If free, enter 0');
            setErrorMessage(newMessages);
        } else {
            handleFormSubmit(formData)
        }
    }

    const displayLiquorTypeSelect = () => {
        const theCat = categories.find((cat) => cat.name.toLowerCase() === 'liquor')
        const theSubs = theCat.subCategories.items ? theCat.subCategories.items : []
        if (theSubs.length < 1) return;
        let formattedSubs = []
        for (const sub of theSubs) {
            const newSub = {
                value: sub.id,
                label: sub.name
            }
            formattedSubs.push(newSub)
        }

        return (
            <div className="formInput" key='drinkSubCategory'>
                <MustSelectLiquorText>* must select to allow liquor brand upgrades</MustSelectLiquorText>
                <Select 
                    name='liquorType' 
                    defaultValue={formattedSubs.find(sub => sub.label.toLowerCase() === editingMenuItem?.description?.toLowerCase())} 
                    options={formattedSubs}
                    onChange={handleLiquorTypeChange}
                />
            </div>
        )
    }

    const displaySubCategories = () => {
        if (!formData.drinkCategory || formData.drinkCategory === '') {
            return []
        }
        const theMainCat = formData.drinkCategory;
        if (!theMainCat) return;

        const theCat = categories.find((cat) => formData.drinkCategory === cat.name)
        const theSubs = theCat.subCategories.items ? theCat.subCategories.items : []
        if (theSubs.length < 1) return;
        let formattedSubs = []
        for (const sub of theSubs) {
            const newSub = {
                value: sub.name,
                label: sub.name
            }
            formattedSubs.push(newSub)
        }

        return (
            <div className="formInput" key='drinkSubCategory'>
                <label>Sub Category (* optional)</label>
                <Select 
                    name='drinkSubCategory' 
                    defaultValue={{label: formData.drinkSubCategory, value: formData.drinkSubCategory}} 
                    options={formattedSubs}
                    onChange={handleSubCategoryChange}
                />
            </div>
        )
    }

    const handleSubCategoryChange = (sub) => {
        const newFormData = {
            ...formData,
            drinkSubCategory: sub.value
        }
        setFormData(newFormData)
    }

    const handleLiquorTypeChange = (liquorType) => {
        const newFormData = {
            ...formData,
            drinkDescription: liquorType.label
        }
        setFormData(newFormData)
    }

    const handleCategoryChange = (cat) => {
        const newFormData = {
            ...formData,
            drinkCategory: cat.value
        }
        setFormData(newFormData)
    }

    const handleInputChange = (event) => {
        setErrorMessage([]);
        const value = event.target.value;
        setFormData({
            ...formData,
            [event.target.name] : value
        })
    }

    const isMixedDrink = () => {
        if (formData?.drinkCategory?.toLowerCase() === 'mixeddrinks') return true;
        if (editingMenuItem?.mainCategory?.name?.toLowerCase() === 'mixeddrinks') return true;
        return false;
    }

    return (
        <Modal
            open={modalState}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='edit-menu-item-wrapper'>
                <form>
                        <div className="formInput" key='drinkName'>
                            <label>Drink Name</label>
                            <input 
                                type='text' 
                                placeholder="Beer"
                                name='drinkName'
                                value={formData.drinkName}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="formInput" key='drinkPrice'>
                            <label>Price</label>
                            <input 
                                type='text' 
                                placeholder="4.99"
                                name='drinkPrice'
                                value={formData.drinkPrice}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="formInput" key='drinkCategory'>
                            <label>Category</label>
                            {categoryData.length > 0 && <Select 
                                name='drinkCategory' 
                                defaultValue={{value: formData.drinkCategory, label: formData.drinkCategory}} 
                                onChange={handleCategoryChange}
                                options={categoryData}
                            />}
                                {/* <option value="">Select Category</option> */}
                                {/* {categories && categories.map(cat => {
                                    if (!cat.name) return null;
                                    return <option key={cat.name} value={cat.name}>{cat.name}</option>
                                })} */}
                        </div>
                        {displaySubCategories()}

                        <div className="formInput" key='drinkDescription'>
                            <label>Liquor Type or Desciption</label>
                            {isMixedDrink() ? 
                                displayLiquorTypeSelect()
                                :
                                <input 
                                    type='text' 
                                    placeholder="Domestic Draft"
                                    name='drinkDescription'
                                    value={formData.drinkDescription}
                                    onChange={handleInputChange}
                                />
                }
                        </div>
                        
                        {errorMessage.length > 0 && 
                            <div className='edit-modal-error-message-wrap'>
                                {errorMessage.map((mes, index) => <div key={index} className='edit-message-error-message'>Error: {mes}</div>)}
                            </div>
                        }
                        <SaveMenuItemsButtonsRow>
                            <SaveMenuItemButton type="button" onClick={handleModalClose} >Cancel</SaveMenuItemButton>
                            <SaveMenuItemButton type='button' onClick={() => handleSaveMenuItem(formData)}>Save Menu Item</SaveMenuItemButton>
                        </SaveMenuItemsButtonsRow>
                </form>
            </div>
        </Modal>
    )
}

export default EditMenuItemModal;