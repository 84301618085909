import "./vendors-datatable.scss"
import * as React from 'react';
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import {API, graphqlOperation} from "aws-amplify";
import { RotatingLines } from 'react-loader-spinner'
import {listVendorsAndFees} from '../../graphql/customQueries';
import { vendorColumns } from './drinklinkAdminVendorsDataTablesource';
import DrinklinkAdminEditVendorModal from "../../pages/DrinklinkAdminPages/drinklink-admin-edit-vendor-modal";
import { updateVendors } from "../../graphql/mutations";
import {formatMoney} from '../../utils/utils';

const DrinklinkAdminVendorsDatatable = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [editVendorModalOpen, setEditVendorModalOpen] = useState(false)
    const [selectedVendorToEdit, setSelectedVendorToEdit] = useState()
    const [defaultFee, setDefaultFee] = useState(null)
    const [feeSchedules, setFeeSchedules] = useState([]);

    const handleEditVendorModalClose = () => {
        setEditVendorModalOpen(false)
    }

    const getDefaultFeeAndSchedules = async (vendors) => {
        try {
            const feeSchedulesResp = await API.graphql({
                query: `
                    query ListFeeSchedules {
                        listFeeSchedules {
                            items {
                                id
                                fee
                                name
                            }
                        }
                    }
                `
            });
            const feeSchedules = feeSchedulesResp.data.listFeeSchedules.items
            const defaultFee = feeSchedules.find(item => item?.name === 'Default');
            setDefaultFee(defaultFee.fee)
            const formattedFeeSchedules = feeSchedules.map(fee => {
                return {
                    value: fee.id,
                    label: `${fee.fee} - ${fee.name}`
                }
            })
            setFeeSchedules(formattedFeeSchedules)
            
        } catch (e) {
            console.log('error fetching fee schedules', e)
        }
        
    }

    const fetchData = async () => {
        try {
            const vendRet = await API.graphql(graphqlOperation(listVendorsAndFees, { filter: {}}))
            const allVendors = vendRet.data.listVendors.items
            setData(allVendors)
            getDefaultFeeAndSchedules()
            setIsLoading(false);
        } catch (e) {
            console.log('error fetching vendors', e)
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    const handleEditVendor = (vendor) => {
        setSelectedVendorToEdit(vendor)
        setEditVendorModalOpen(true)
    }

    const handleEditVendorSubmit = async (values) => {
        const updateObject = {
            id: selectedVendorToEdit.id
        }
        const updatedUserVendorSelected = selectedVendorToEdit;
        if (values.vendorCity !== selectedVendorToEdit.vendorCity){
            updateObject.vendorCity = values.vendorCity.toLowerCase();
            updatedUserVendorSelected.vendorCity = values.vendorCity.toLowerCase();
        }
        if (values.vendorContactPhone !== selectedVendorToEdit.vendorContactPhone) {
            updateObject.vendorContactPhone = values.vendorContactPhone;
            updatedUserVendorSelected.vendorContactPhone = values.vendorContactPhone;
        }
        if (values.vendorEmail !== selectedVendorToEdit.vendorEmail) {
            updateObject.vendorEmail = values.vendorEmail;
            updatedUserVendorSelected.vendorEmail = values.vendorEmail;
        }
        if (values.vendorName !== selectedVendorToEdit.vendorName) {
            updateObject.vendorName = values.vendorName;
            updatedUserVendorSelected.vendorName = values.vendorName;
        }
        if (values.vendorState !== selectedVendorToEdit.vendorState) {
            updateObject.vendorState = values.vendorState.toLowerCase();
            updatedUserVendorSelected.vendorState = values.vendorState.toLowerCase();
        }
        if (values.vendorStreet !== selectedVendorToEdit.vendorStreet) {
            updateObject.vendorStreet = values.vendorStreet;
            updatedUserVendorSelected.vendorStreet = values.vendorStreet;
        }
        if (values.vendorZip !== selectedVendorToEdit.vendorZip) {
            updateObject.vendorZip = values.vendorZip;
            updatedUserVendorSelected.vendorZip = values.vendorZip;
        }
        if (values.status !== selectedVendorToEdit.status) {
            updateObject.status = values.status;
            updatedUserVendorSelected.status = values.status;
        }
        if (values.vendorDisplayStatus !== selectedVendorToEdit.vendorDisplayStatus) {
            updateObject.vendorDisplayStatus = values.vendorDisplayStatus;
            updatedUserVendorSelected.vendorDisplayStatus = values.vendorDisplayStatus;
        }
        if (values.waveDrinklinkFee !== selectedVendorToEdit.waveDrinklinkFee) {
            updateObject.waveDrinklinkFee = values.waveDrinklinkFee;
            updatedUserVendorSelected.waveDrinklinkFee = values.waveDrinklinkFee;
        }
        if (values.feeScheduleID !== selectedVendorToEdit.feeScheduleID) {
            updateObject.feeScheduleID = values.feeScheduleID;
            updatedUserVendorSelected.feeScheduleID = values.feeScheduleID;
        }
        try {
            await API.graphql(graphqlOperation(updateVendors, {input: updateObject}))
            await fetchData()
        } catch (e) {
            console.log('error updating vendor', e)
        }
        setEditVendorModalOpen(false)
    }

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 600,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <div
                            className="viewButton"
                            onClick={() => handleEditVendor(params.row)}
                        >
                            Edit Vendor
                        </div>
                    </div>
                )
            }
        }
    ]
    return (
        <>
            <div className="datatable">
                <div className="datatableTitle">
                    <div className="TitleLeft">All Vendors</div>
                    <div className="TitleRight">Default Fee: ${formatMoney(defaultFee).toString()}</div>
                </div>
                {!isLoading ? 
                    <DataGrid
                        className="datagrid"
                        rows={data}
                        columns={vendorColumns.concat(actionColumn)}
                        pageSize={9}
                        rowsPerPageOptions={[25]}
                        //checkboxSelection
                    />
                    :
                    <div className='loading-gif'>
                        <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="96"
                            visible={true}
                        />
                    </div>
                }
            </div>
            {editVendorModalOpen &&
                <DrinklinkAdminEditVendorModal 
                    modalState={editVendorModalOpen}
                    handleModalClose={handleEditVendorModalClose}
                    handleFormSubmit={handleEditVendorSubmit}
                    editingVendor={selectedVendorToEdit}
                    feeSchedules={feeSchedules}
                />
            }
        </>
    )
}

export default DrinklinkAdminVendorsDatatable