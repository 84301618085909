import * as React from 'react';
import { useContext, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { FaBars, FaTimes } from 'react-icons/fa'; // Icons for hamburger and close
import {Auth} from 'aws-amplify';

import "./landing.scss";

import { AuthContext } from '../../context/AuthContext';
import { VendorUserContext } from '../../context/VendorUserContext';

const TopNavBar = () => {
    const {currentUser, dispatch: authDispatch} = useContext(AuthContext);
    const { dispatch } = useContext(VendorUserContext);
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false); // State to manage menu open/close

    const handleLogout = async(e) => {
        try {
            await Auth.signOut()
            authDispatch({ type: "LOGOUT", payload: null});
            dispatch({type: "UNSET_USER_VENDORS", payload: null});
            dispatch({type: "UNSET_USER_VENDOR_SELECTED", payload: null})
            navigate("/");
        } catch (err) {
            console.log('error signing out: ', err)
        }
    }

    const toggleMenu = () => setMenuOpen(!menuOpen); // Toggle the hamburger menu

    return (
        <div className="landing-top-nav">
            <div className="landing-logo">
                <Link to="/">
                    <img src={require('../../assets/DrinkLink_Logo-V2-white.png')} alt="drinklink logo"/>
                </Link>
            </div>
            <div className={`landing-nav ${menuOpen ? 'open' : ''}`}> {/* Add open class when menu is open */}
                <div className="landing-nav-item">
                    <Link to="/">
                        <span className="link-text">Home</span>
                    </Link>
                </div>
                { currentUser ? 
                    <>
                        <div className="landing-nav-item">
                            <Link to="/my-account">
                                <span className="link-text">My Account</span>
                            </Link>
                        </div>
                        { currentUser.isVendorUser && 
                            <div className="landing-nav-item">
                                <Link to="/selectVendor">
                                    <span className="link-text">My Vendors</span>
                                </Link>
                            </div> 
                        }
                        { currentUser.drinklinkUser && currentUser.drinklinkUser.role &&
                            <div className="landing-nav-item">
                                <Link to="/drinklinkAdmin">
                                    <span className="link-text">Drinklink Admin</span>
                                </Link>
                            </div> 
                        }
                        <div className="landing-nav-item" onClick={() => handleLogout()}>
                                <span className="link-text">Logout</span>
                        </div>
                        
                    </>
                    :
                    <>
                        <div className="landing-nav-item">
                            <Link to="/login">
                                <span className="link-text">Login</span>
                            </Link>
                        </div>
                    </>
                }
                
                
                
            </div>
            {/* Hamburger Menu Icon */}
            <div className="hamburger-menu" onClick={toggleMenu}>
                {menuOpen ? <FaTimes /> : <FaBars />} {/* Toggle between open and close icons */}
            </div>
        </div>
    )
}

export default TopNavBar;