import * as React from 'react';
import {useState} from 'react';
import "./createInvoiceModal.scss";
import styled from 'styled-components';
import Modal from '@mui/material/Modal';
import moment from 'moment';

const SaveItemButton = styled.button`
    justify-content: center;
    align-items: center;
    padding: 0px;
    border-radius: 6px;
`;

const SaveItemsButtonsRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
`;

const InputWrapper = styled.div`
    margin-bottom: 20px;
`;    

const Input = styled.input`
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 8px;
    color: #555;
    font-size: 14px;
`;

const VendorAccessDetailsWrap = styled.div`
    display: flex;
    flex-direction: row;
    font-size: 12px;
`

const VendorAccessDetailsLeft = styled.div`
    margin-right: 20px;
    display: flex;
    flex-direction: column;
`
const VendorAccessDetailsRight = styled.div`
    margin-right: 20px;
    display: flex;
    flex-direction: column;
`
const VendorAccessDetailsItem = styled.div`
    margin-bottom: 20px;
`
const VendorAccessDetailsTitle = styled.div`
    font-weight: bold;
`
const VendorAccessDetailsData = styled.div``

const VendorContactsMade = styled.div``

const NoteItem = styled.div`
    margin-bottom: 20px;
    font-size: 12px;
`
const NoteItemTitle = styled.div`
    font-weight: bold;
`
const NoteItemText = styled.div`
`
const VendorContactsMadeTitle = styled.div`
    font-weight: bold;
    margin-top: 20px;
    font-size: 16px;
`

const VendorAccessDetailsModal = ({modalState, handleModalClose, handleFormSubmit, vendorRequestData, modalType}) => {
    const [note, setNote] = useState('');

    const handleInputChange = (event) => {
        const value = event.target.value;
         
        setNote(value)
    }

    const contactsItem = (contact, index) => {
        return (
            <NoteItem key={index}>
                <NoteItemTitle>{moment(contact.date).format('MM/DD/YYYY hh:mm A')}</NoteItemTitle>
                <NoteItemText>{contact.note}</NoteItemText>
            </NoteItem>
        )
    }
    

    const getContactsMade = () => {
        if (!vendorRequestData.contactsMade) {
            return (
                <NoteItem key={`1`}>
                    <NoteItemTitle>None yet</NoteItemTitle>
                </NoteItem>
            )
        }
        const contactsMade = JSON.parse(vendorRequestData.contactsMade);
        return contactsMade.map((contact, index) => contactsItem(contact, index))
    }

    return (
      <Modal
        open={modalState}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className='create-invoice-item-wrapper'>
            {modalType === 'complete' ?
                <h2>Complete Vendor Request</h2> 
             : 
                <>
                    <VendorAccessDetailsWrap>
                        <VendorAccessDetailsLeft>
                            <VendorAccessDetailsItem>
                                <VendorAccessDetailsTitle>Contact Name:</VendorAccessDetailsTitle>
                                <VendorAccessDetailsData>{vendorRequestData.contactName}</VendorAccessDetailsData>
                            </VendorAccessDetailsItem>
                            <VendorAccessDetailsItem>
                                <VendorAccessDetailsTitle>Contact Email:</VendorAccessDetailsTitle>
                                <VendorAccessDetailsData>{vendorRequestData.contactEmail}</VendorAccessDetailsData>
                            </VendorAccessDetailsItem>
                            <VendorAccessDetailsItem>
                                <VendorAccessDetailsTitle>Contact Phone:</VendorAccessDetailsTitle>
                                <VendorAccessDetailsData>{vendorRequestData.contactPhone}</VendorAccessDetailsData>
                            </VendorAccessDetailsItem>
                        </VendorAccessDetailsLeft>
                        <VendorAccessDetailsRight>
                            <VendorAccessDetailsItem>
                                <VendorAccessDetailsTitle>Vendor Name:</VendorAccessDetailsTitle>
                                <VendorAccessDetailsData>{vendorRequestData.vendorName}</VendorAccessDetailsData>
                            </VendorAccessDetailsItem>
                            <VendorAccessDetailsItem>
                                <VendorAccessDetailsTitle>Vendor Phone:</VendorAccessDetailsTitle>
                                <VendorAccessDetailsData>{vendorRequestData.vendorContactPhone}</VendorAccessDetailsData>
                            </VendorAccessDetailsItem>
                            <VendorAccessDetailsItem>
                                <VendorAccessDetailsTitle>Vendor Address:</VendorAccessDetailsTitle>
                                <VendorAccessDetailsData>{vendorRequestData.vendorStreet}</VendorAccessDetailsData>
                                <VendorAccessDetailsData>{vendorRequestData.vendorCity}, {vendorRequestData.vendorState} {vendorRequestData.vendorZip}</VendorAccessDetailsData>
                            </VendorAccessDetailsItem>
                        </VendorAccessDetailsRight>
                    </VendorAccessDetailsWrap>
                    <VendorContactsMadeTitle>Notes: </VendorContactsMadeTitle>
                    <VendorContactsMade>
                        {getContactsMade()}
                    </VendorContactsMade>
                </>
            }
            <form>
                {modalType !== 'complete' && 
                    <InputWrapper>
                        <Label>New Note: (i.e. called John Doe. No answer)</Label>
                        <Input type="text" name="note" value={note} onChange={handleInputChange} required />
                    </InputWrapper>
                }
                <SaveItemsButtonsRow>
                    <SaveItemButton type="button" onClick={handleModalClose} >Cancel</SaveItemButton>
                    <SaveItemButton type='button' onClick={() => handleFormSubmit(note)}>
                        { modalType === 'complete' ? 'Done: Remove from List' :  'Save Note'}
                    </SaveItemButton>
                </SaveItemsButtonsRow>
            </form>
        </div>
      </Modal>
    )

}

export default VendorAccessDetailsModal;