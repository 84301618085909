import "./users-datatable.scss";
import * as React from 'react';
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState, useContext } from "react";
import { isValidPhoneNumber } from 'react-phone-number-input';
import {API, graphqlOperation} from "aws-amplify";
// import {
//     collection,
//     getDocs,
//     where,
//     query,
//     documentId,
//     addDoc,
//     doc,
//     updateDoc,
//     deleteDoc
// } from "firebase/firestore";
import {
    getUsers
} from "../../../src/graphql/queries"
import { listVendorUsersWithUserData, listVendorUsersWithVendorData } from "../../../src/graphql/customQueries"

import { RotatingLines } from 'react-loader-spinner'

//import { db } from '../../firebase';
import AddUserModal from "./add-user-modal";
import EditUserRoleModal from "./edit-user-role-modal";
import { userColumns } from "./userdatatablesource";
import { VendorUserContext } from "../../context/VendorUserContext";
import RemoveUserConfirmModal from "./remove-user-confirm-modal";
import {createVendorUsers, deleteVendorUsers, updateVendorUsers} from "../../graphql/mutations";

const UsersDatatable = () => {

    const [data, setData] = useState([]);
    const [addUserModalOpen, setAddUserModalOpen] = useState(false);
    const [editUserRoleModalOpen, setEditUserRoleModalOpen] = useState(false);
    const [removeUserConfirmModalOpen, setRemoveUserConfirmModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [existingUser, setExistingUser] = useState(false);
    const [existingMember, setExistingMember] = useState(true);
    const [selectedUserToEdit, setSelectedUserToEdit] = useState();
    const { userVendorSelected } = useContext(VendorUserContext);

    const handleAddUserModalClose = () => {
        setExistingMember(true);
        setExistingUser(false);
        setAddUserModalOpen(false);
    }

    const handleEditUserRoleModalClose = () => {
        setEditUserRoleModalOpen(false);
    }

    const handleRemoveUserConfirmModalClose = () => {
        setRemoveUserConfirmModalOpen(false);
    }

    useEffect(() => {
        const fetchData = async () => {
            console.log('userVendorSelected', userVendorSelected.id);
            try {
                const vendorUsersRet = await API.graphql(graphqlOperation(
                    listVendorUsersWithVendorData, {
                        filter: {
                            vendorsID: {eq: userVendorSelected.id}
                        }
                    }
                ))
                const vendorUsers = vendorUsersRet.data.listVendorUsers.items;
                console.log('vendorUsers -------', vendorUsers);
                // remove any users that aren't found in the users table
                const cleanVendorUsers = vendorUsers.filter(item => item.users);
                console.log('vendorUsersxxxx', cleanVendorUsers);
                setData(cleanVendorUsers);
                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
            }
        };
        if (userVendorSelected) {
            fetchData();
        }
    }, []);

    console.log('vendorUsers', data);

    const handleSelectEditRole = (rowData) => {
        setSelectedUserToEdit({userId: rowData.usersID, userRole: rowData.role, vendorUserId: rowData.id});
        setEditUserRoleModalOpen(true);
    }

    const getVendorUser = async (userId) => {
        const vendorUsersRet = await API.graphql(graphqlOperation(
            listVendorUsersWithUserData, {
                filter: {
                    and: {
                        vendorsID: {eq: userVendorSelected.id},
                        usersID: {eq: userId}
                    }
                    
                }
            }
        ))
        const theUser = vendorUsersRet.data.listVendorUsers.items &&
                    vendorUsersRet.data.listVendorUsers.items.length > 0 ?
                    vendorUsersRet.data.listVendorUsers.items[0] : null
        return theUser
    }

    const addVendorUser = async (phone, role, userId) => {
        const newInput = {
            role: role,
            status: 'active',
            usersID: userId,
            vendorsID: userVendorSelected.id
        }
        try {
            await API.graphql(graphqlOperation(createVendorUsers, {input: newInput}))
        } catch (e) {
            console.log('error adding vendorUser', e)
        }
    }

    const handleAddUserFormSubmit = async (member, userRole) => {
        if (member) {
            const theVendorUser = await getVendorUser(member.id);
            if (theVendorUser) {
                // already a vendor user.
                alert('Member is already a user.')
                handleAddUserModalClose();
                return;
            }
            await addVendorUser(member.phone, userRole, member.id);
            const newData = [...data];
            const newUserData = member;
            newUserData.role = userRole;
            newUserData.status = 'active';
            newData.push(newUserData);
            setData(newData);
            handleAddUserModalClose();
            return;
        } else {
            setExistingMember(false);
        }
    }

    const handleEditUserRoleFormSubmit = async (userId, userRole) => {
        try {
            const newInput = {
                id: selectedUserToEdit.vendorUserId,
                role: userRole
            }
            await API.graphql(graphqlOperation(updateVendorUsers, {input: newInput}))
            const foundIndex = data.findIndex(item => item.id === selectedUserToEdit.vendorUserId);
            const updatedData = [
                ...data
            ]
            updatedData[foundIndex].role = userRole;
            setData(updatedData);
            handleEditUserRoleModalClose();
        } catch (e) {
            console.log('error updating role: ', e);
        }
        
    }

    const handleAddUser = () => {
        setAddUserModalOpen(true);
    }

    const handleUserRemove = async () => {
        try {
            await API.graphql(graphqlOperation(deleteVendorUsers, {input: {id: selectedUserToEdit.id}}))
            let updatedData = [...data];
            updatedData = updatedData.filter(item => item.id !== selectedUserToEdit.id);
            setData(updatedData);
            handleRemoveUserConfirmModalClose();
        } catch (e) {
            console.log('error removing user from this vendor', e);
        }
    }

    const handleSelectUserToRemove = (rowData) => {
        setSelectedUserToEdit(rowData);
        setRemoveUserConfirmModalOpen(true);
    }

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: window.location.href.includes('/menuItems') ? 200 : 600,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <div 
                            className="viewButton"
                            onClick={() => handleSelectEditRole(params.row)}
                        >   
                            Edit Role
                        </div>
                        {/*<div 
                            className="viewButton"
                            //onClick={() => handleUserEdit(params.row, 'resend-invite')}
                        >   
                            Resend Invite
                </div> */}
                        <div
                            className="deleteButton"
                            onClick={() => handleSelectUserToRemove(params.row)}
                        >
                            Remove
                        </div>
                    </div>
                );
            },
        },
    ];
    return (
        <>
            <div className="datatable">
                <div className="datatableTitle">
                    Current Users
                    { userVendorSelected.role !== 'viewer' && 
                        <div className="dataTableTitleButtons">
                            <div onClick={() => handleAddUser(null, 'add-user')} className="link">
                                Add New User
                            </div>
                        </div>
                    }
                </div>
                {!isLoading ? 
                    <DataGrid
                        className="datagrid"
                        rows={data}
                        columns={userVendorSelected.role !== 'viewer' ? userColumns.concat(actionColumn) : userColumns}
                        pageSize={9}
                        rowsPerPageOptions={[25]}
                        //checkboxSelection
                    />
                    :
                    <div className='loading-gif'>
                        <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="96"
                            visible={true}
                        />
                    </div>
                }
            </div>
            {addUserModalOpen &&
                <AddUserModal
                    modalState={addUserModalOpen}
                    handleModalClose={handleAddUserModalClose}
                    handleFormSubmit={handleAddUserFormSubmit}
                />
            }
            {editUserRoleModalOpen &&
                <EditUserRoleModal 
                    modalState={editUserRoleModalOpen}
                    handleModalClose={handleEditUserRoleModalClose}
                    handleFormSubmit={handleEditUserRoleFormSubmit}
                    editingUser={selectedUserToEdit}
                />
            }
            {removeUserConfirmModalOpen &&
                <RemoveUserConfirmModal 
                    modalState={removeUserConfirmModalOpen}
                    handleModalClose={handleRemoveUserConfirmModalClose}
                    handleFormSubmit={handleUserRemove}
                    editingUser={selectedUserToEdit}
                />
            }
        </>
    );
}

export default UsersDatatable;