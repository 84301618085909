import "./payments-received-datatable.scss";
import * as React from 'react';
import { DataGrid } from "@mui/x-data-grid";
import { Storage } from 'aws-amplify';
import styled from 'styled-components';
import { useEffect, useState, useContext } from "react";
import {API, graphqlOperation} from "aws-amplify";
import { RotatingLines } from 'react-loader-spinner';
import {paymentsColumns} from './paymentsRecievedDatatableSource';
import {listVendorPaymentsWithData} from "../../graphql/customQueries";
import { VendorUserContext } from "../../context/VendorUserContext";

const ActionButtonsWrap = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`
const DrinkTotalSection = styled.div`
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
`

const PaymentsReceivedDatatable = () => {
    const [vendorPayments, setVendorPayments] = useState([])
    const [vendorPayment, setVendorPayment] = useState()
    const [view, setView] = useState('vendors')
    const [isLoading, setIsLoading] = useState(true);
    const [pdfUrl, setPdfUrl] = useState()

    const { userVendorSelected } = useContext(VendorUserContext);

    const fetchVendorPayments = async () => {
        const vendorPaymentsReturn = await API.graphql(graphqlOperation(listVendorPaymentsWithData, {vendorID: userVendorSelected.id}))
        const vendorPayments = vendorPaymentsReturn.data.listVendorPayments.items
        setVendorPayments(vendorPayments)
        setIsLoading(false)
    }

    useEffect(() => {
        const fetchData = async () => {
          fetchVendorPayments()
        }
        fetchData()
    }, [])

    const setPDFUrl = async () => {
        const url = await Storage.get(vendorPayment.invoiceURL); 
        setPdfUrl(url)
    }

    useEffect(() => {
        if (view === 'pdfView') {
            setPDFUrl()
        }
    }, [view])

    const onBackToDetails = () => {
        setView('details');
    }

    const handleViewInvoice = (row) => {
        setVendorPayment(row)
        setView('pdfView')
    }

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: window.location.href.includes('/menuItems') ? 200 : 600,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <div 
                            className="viewButton"
                            onClick={() => handleViewInvoice(params.row)}
                        >   
                            View Invoice
                        </div>
                    </div>
                );
            },
        },
    ];

    const displayPDFView = () => {
        return (
            <div className="pdf-viewer-page">
                <div className="datatableTitle">
                    <ActionButtonsWrap>
                        <div className="dataTableTitleButtons">
                            <div onClick={() => onBackToDetails()} className="link">
                                Back to Payments
                            </div>
                        </div>
                    </ActionButtonsWrap>
                    </div>
                    {pdfUrl ?
                            <iframe title='mypdf' src={pdfUrl} width="100%" height="600px" />
                    :
                    <div>loading...</div>
                    }
                
            </div>
        )
    }

    const returnView = () => {
        if (view === 'pdfView') {
            return displayPDFView()
        } else {
            return (
                <>
                    <DrinkTotalSection>{userVendorSelected.vendorName} Payments from Drinklink:</DrinkTotalSection>
                    <DataGrid
                        className="datagrid"
                        rows={vendorPayments}
                        columns={paymentsColumns.concat(actionColumn)}
                        pageSize={9}
                        rowsPerPageOptions={[25]}
                        //checkboxSelection
                    />
                </>
            )
        }
    }

    return (
        <>
            <div className="datatable">
                {!isLoading ?
                    <>
                        {returnView()}
                    </>
                    
                    :
                    <div className='loading-gif'>
                        <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="96"
                            visible={true}
                        />
                    </div>
                }
            </div>
        </>
    );
}

export default PaymentsReceivedDatatable