/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUsers = /* GraphQL */ `
  subscription OnCreateUsers($filter: ModelSubscriptionUsersFilterInput) {
    onCreateUsers(filter: $filter) {
      id
      avatarImage
      birthDate
      email
      fName
      homeCity
      homeState
      isVendorUser
      lName
      lowerCaseUsername
      phone
      smsNotificationsAccept
      stripeCustomerId
      username
      emailNotificationsAccept
      userAskedToDelete
      deletedAt
      Friends {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUsers = /* GraphQL */ `
  subscription OnUpdateUsers($filter: ModelSubscriptionUsersFilterInput) {
    onUpdateUsers(filter: $filter) {
      id
      avatarImage
      birthDate
      email
      fName
      homeCity
      homeState
      isVendorUser
      lName
      lowerCaseUsername
      phone
      smsNotificationsAccept
      stripeCustomerId
      username
      emailNotificationsAccept
      userAskedToDelete
      deletedAt
      Friends {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUsers = /* GraphQL */ `
  subscription OnDeleteUsers($filter: ModelSubscriptionUsersFilterInput) {
    onDeleteUsers(filter: $filter) {
      id
      avatarImage
      birthDate
      email
      fName
      homeCity
      homeState
      isVendorUser
      lName
      lowerCaseUsername
      phone
      smsNotificationsAccept
      stripeCustomerId
      username
      emailNotificationsAccept
      userAskedToDelete
      deletedAt
      Friends {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateMainCategories = /* GraphQL */ `
  subscription OnCreateMainCategories(
    $filter: ModelSubscriptionMainCategoriesFilterInput
  ) {
    onCreateMainCategories(filter: $filter) {
      id
      MenuItems {
        nextToken
        __typename
      }
      name
      vendorsID
      subCategories {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateMainCategories = /* GraphQL */ `
  subscription OnUpdateMainCategories(
    $filter: ModelSubscriptionMainCategoriesFilterInput
  ) {
    onUpdateMainCategories(filter: $filter) {
      id
      MenuItems {
        nextToken
        __typename
      }
      name
      vendorsID
      subCategories {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteMainCategories = /* GraphQL */ `
  subscription OnDeleteMainCategories(
    $filter: ModelSubscriptionMainCategoriesFilterInput
  ) {
    onDeleteMainCategories(filter: $filter) {
      id
      MenuItems {
        nextToken
        __typename
      }
      name
      vendorsID
      subCategories {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSubCategories = /* GraphQL */ `
  subscription OnCreateSubCategories(
    $filter: ModelSubscriptionSubCategoriesFilterInput
  ) {
    onCreateSubCategories(filter: $filter) {
      id
      MenuItems {
        nextToken
        __typename
      }
      name
      vendorsID
      mainCategoriesID
      mainCategory {
        id
        name
        vendorsID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSubCategories = /* GraphQL */ `
  subscription OnUpdateSubCategories(
    $filter: ModelSubscriptionSubCategoriesFilterInput
  ) {
    onUpdateSubCategories(filter: $filter) {
      id
      MenuItems {
        nextToken
        __typename
      }
      name
      vendorsID
      mainCategoriesID
      mainCategory {
        id
        name
        vendorsID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSubCategories = /* GraphQL */ `
  subscription OnDeleteSubCategories(
    $filter: ModelSubscriptionSubCategoriesFilterInput
  ) {
    onDeleteSubCategories(filter: $filter) {
      id
      MenuItems {
        nextToken
        __typename
      }
      name
      vendorsID
      mainCategoriesID
      mainCategory {
        id
        name
        vendorsID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateMenuItems = /* GraphQL */ `
  subscription OnCreateMenuItems(
    $filter: ModelSubscriptionMenuItemsFilterInput
  ) {
    onCreateMenuItems(filter: $filter) {
      id
      description
      drink
      price
      vendorsID
      mainCategoriesID
      mainCategory {
        id
        name
        vendorsID
        createdAt
        updatedAt
        __typename
      }
      subCategoriesID
      subCategory {
        id
        name
        vendorsID
        mainCategoriesID
        createdAt
        updatedAt
        __typename
      }
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateMenuItems = /* GraphQL */ `
  subscription OnUpdateMenuItems(
    $filter: ModelSubscriptionMenuItemsFilterInput
  ) {
    onUpdateMenuItems(filter: $filter) {
      id
      description
      drink
      price
      vendorsID
      mainCategoriesID
      mainCategory {
        id
        name
        vendorsID
        createdAt
        updatedAt
        __typename
      }
      subCategoriesID
      subCategory {
        id
        name
        vendorsID
        mainCategoriesID
        createdAt
        updatedAt
        __typename
      }
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteMenuItems = /* GraphQL */ `
  subscription OnDeleteMenuItems(
    $filter: ModelSubscriptionMenuItemsFilterInput
  ) {
    onDeleteMenuItems(filter: $filter) {
      id
      description
      drink
      price
      vendorsID
      mainCategoriesID
      mainCategory {
        id
        name
        vendorsID
        createdAt
        updatedAt
        __typename
      }
      subCategoriesID
      subCategory {
        id
        name
        vendorsID
        mainCategoriesID
        createdAt
        updatedAt
        __typename
      }
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateMenuItemsPurchased = /* GraphQL */ `
  subscription OnCreateMenuItemsPurchased(
    $filter: ModelSubscriptionMenuItemsPurchasedFilterInput
  ) {
    onCreateMenuItemsPurchased(filter: $filter) {
      id
      category
      personalMessage
      state
      stateHistory
      stripeStatus
      stripeStatusHistory
      stripeReceiptUrl
      drinkCost
      fees
      menuItemDescription
      menuItemName
      stripePaymentIntentID
      tip
      purchaserID
      purchaser {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      receiverID
      receiver {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      vendorsID
      vendor {
        id
        status
        vendorDisplayStatus
        vendorCity
        vendorContactPhone
        vendorEmail
        vendorImage
        vendorName
        vendorState
        vendorStreet
        vendorZip
        waveDrinklinkFee
        feeScheduleID
        createdAt
        updatedAt
        __typename
      }
      vendorNotes
      vendorPaymentID
      vendorPayment {
        id
        amount
        paymentDate
        vendorsID
        adminUserID
        paidBy
        invoiceNumber
        invoiceURL
        createdAt
        updatedAt
        __typename
      }
      nonMemberToken
      nonMemberPhone
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateMenuItemsPurchased = /* GraphQL */ `
  subscription OnUpdateMenuItemsPurchased(
    $filter: ModelSubscriptionMenuItemsPurchasedFilterInput
  ) {
    onUpdateMenuItemsPurchased(filter: $filter) {
      id
      category
      personalMessage
      state
      stateHistory
      stripeStatus
      stripeStatusHistory
      stripeReceiptUrl
      drinkCost
      fees
      menuItemDescription
      menuItemName
      stripePaymentIntentID
      tip
      purchaserID
      purchaser {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      receiverID
      receiver {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      vendorsID
      vendor {
        id
        status
        vendorDisplayStatus
        vendorCity
        vendorContactPhone
        vendorEmail
        vendorImage
        vendorName
        vendorState
        vendorStreet
        vendorZip
        waveDrinklinkFee
        feeScheduleID
        createdAt
        updatedAt
        __typename
      }
      vendorNotes
      vendorPaymentID
      vendorPayment {
        id
        amount
        paymentDate
        vendorsID
        adminUserID
        paidBy
        invoiceNumber
        invoiceURL
        createdAt
        updatedAt
        __typename
      }
      nonMemberToken
      nonMemberPhone
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteMenuItemsPurchased = /* GraphQL */ `
  subscription OnDeleteMenuItemsPurchased(
    $filter: ModelSubscriptionMenuItemsPurchasedFilterInput
  ) {
    onDeleteMenuItemsPurchased(filter: $filter) {
      id
      category
      personalMessage
      state
      stateHistory
      stripeStatus
      stripeStatusHistory
      stripeReceiptUrl
      drinkCost
      fees
      menuItemDescription
      menuItemName
      stripePaymentIntentID
      tip
      purchaserID
      purchaser {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      receiverID
      receiver {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      vendorsID
      vendor {
        id
        status
        vendorDisplayStatus
        vendorCity
        vendorContactPhone
        vendorEmail
        vendorImage
        vendorName
        vendorState
        vendorStreet
        vendorZip
        waveDrinklinkFee
        feeScheduleID
        createdAt
        updatedAt
        __typename
      }
      vendorNotes
      vendorPaymentID
      vendorPayment {
        id
        amount
        paymentDate
        vendorsID
        adminUserID
        paidBy
        invoiceNumber
        invoiceURL
        createdAt
        updatedAt
        __typename
      }
      nonMemberToken
      nonMemberPhone
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateVendorUsers = /* GraphQL */ `
  subscription OnCreateVendorUsers(
    $filter: ModelSubscriptionVendorUsersFilterInput
  ) {
    onCreateVendorUsers(filter: $filter) {
      id
      lastAccess
      role
      status
      vendorsID
      vendors {
        id
        status
        vendorDisplayStatus
        vendorCity
        vendorContactPhone
        vendorEmail
        vendorImage
        vendorName
        vendorState
        vendorStreet
        vendorZip
        waveDrinklinkFee
        feeScheduleID
        createdAt
        updatedAt
        __typename
      }
      usersID
      users {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateVendorUsers = /* GraphQL */ `
  subscription OnUpdateVendorUsers(
    $filter: ModelSubscriptionVendorUsersFilterInput
  ) {
    onUpdateVendorUsers(filter: $filter) {
      id
      lastAccess
      role
      status
      vendorsID
      vendors {
        id
        status
        vendorDisplayStatus
        vendorCity
        vendorContactPhone
        vendorEmail
        vendorImage
        vendorName
        vendorState
        vendorStreet
        vendorZip
        waveDrinklinkFee
        feeScheduleID
        createdAt
        updatedAt
        __typename
      }
      usersID
      users {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteVendorUsers = /* GraphQL */ `
  subscription OnDeleteVendorUsers(
    $filter: ModelSubscriptionVendorUsersFilterInput
  ) {
    onDeleteVendorUsers(filter: $filter) {
      id
      lastAccess
      role
      status
      vendorsID
      vendors {
        id
        status
        vendorDisplayStatus
        vendorCity
        vendorContactPhone
        vendorEmail
        vendorImage
        vendorName
        vendorState
        vendorStreet
        vendorZip
        waveDrinklinkFee
        feeScheduleID
        createdAt
        updatedAt
        __typename
      }
      usersID
      users {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDrinklinkAdmins = /* GraphQL */ `
  subscription OnCreateDrinklinkAdmins(
    $filter: ModelSubscriptionDrinklinkAdminsFilterInput
  ) {
    onCreateDrinklinkAdmins(filter: $filter) {
      id
      lastAccess
      role
      status
      usersID
      users {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDrinklinkAdmins = /* GraphQL */ `
  subscription OnUpdateDrinklinkAdmins(
    $filter: ModelSubscriptionDrinklinkAdminsFilterInput
  ) {
    onUpdateDrinklinkAdmins(filter: $filter) {
      id
      lastAccess
      role
      status
      usersID
      users {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDrinklinkAdmins = /* GraphQL */ `
  subscription OnDeleteDrinklinkAdmins(
    $filter: ModelSubscriptionDrinklinkAdminsFilterInput
  ) {
    onDeleteDrinklinkAdmins(filter: $filter) {
      id
      lastAccess
      role
      status
      usersID
      users {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateBartenders = /* GraphQL */ `
  subscription OnCreateBartenders(
    $filter: ModelSubscriptionBartendersFilterInput
  ) {
    onCreateBartenders(filter: $filter) {
      id
      fName
      lName
      otherIdentifier
      showOnRedeem
      vendorsID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateBartenders = /* GraphQL */ `
  subscription OnUpdateBartenders(
    $filter: ModelSubscriptionBartendersFilterInput
  ) {
    onUpdateBartenders(filter: $filter) {
      id
      fName
      lName
      otherIdentifier
      showOnRedeem
      vendorsID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteBartenders = /* GraphQL */ `
  subscription OnDeleteBartenders(
    $filter: ModelSubscriptionBartendersFilterInput
  ) {
    onDeleteBartenders(filter: $filter) {
      id
      fName
      lName
      otherIdentifier
      showOnRedeem
      vendorsID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateVendors = /* GraphQL */ `
  subscription OnCreateVendors($filter: ModelSubscriptionVendorsFilterInput) {
    onCreateVendors(filter: $filter) {
      id
      status
      vendorDisplayStatus
      vendorCity
      vendorContactPhone
      vendorEmail
      vendorImage
      vendorName
      vendorState
      vendorStreet
      vendorZip
      MenuItems {
        nextToken
        __typename
      }
      waveDrinklinkFee
      feeScheduleID
      feeSchedule {
        id
        fee
        name
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateVendors = /* GraphQL */ `
  subscription OnUpdateVendors($filter: ModelSubscriptionVendorsFilterInput) {
    onUpdateVendors(filter: $filter) {
      id
      status
      vendorDisplayStatus
      vendorCity
      vendorContactPhone
      vendorEmail
      vendorImage
      vendorName
      vendorState
      vendorStreet
      vendorZip
      MenuItems {
        nextToken
        __typename
      }
      waveDrinklinkFee
      feeScheduleID
      feeSchedule {
        id
        fee
        name
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteVendors = /* GraphQL */ `
  subscription OnDeleteVendors($filter: ModelSubscriptionVendorsFilterInput) {
    onDeleteVendors(filter: $filter) {
      id
      status
      vendorDisplayStatus
      vendorCity
      vendorContactPhone
      vendorEmail
      vendorImage
      vendorName
      vendorState
      vendorStreet
      vendorZip
      MenuItems {
        nextToken
        __typename
      }
      waveDrinklinkFee
      feeScheduleID
      feeSchedule {
        id
        fee
        name
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateFriends = /* GraphQL */ `
  subscription OnCreateFriends($filter: ModelSubscriptionFriendsFilterInput) {
    onCreateFriends(filter: $filter) {
      id
      usersID
      friendUserID
      friendUser {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateFriends = /* GraphQL */ `
  subscription OnUpdateFriends($filter: ModelSubscriptionFriendsFilterInput) {
    onUpdateFriends(filter: $filter) {
      id
      usersID
      friendUserID
      friendUser {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteFriends = /* GraphQL */ `
  subscription OnDeleteFriends($filter: ModelSubscriptionFriendsFilterInput) {
    onDeleteFriends(filter: $filter) {
      id
      usersID
      friendUserID
      friendUser {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateMenuItemsSentToBar = /* GraphQL */ `
  subscription OnCreateMenuItemsSentToBar(
    $filter: ModelSubscriptionMenuItemsSentToBarFilterInput
  ) {
    onCreateMenuItemsSentToBar(filter: $filter) {
      receiverName
      id
      itemID
      item {
        id
        category
        personalMessage
        state
        stateHistory
        stripeStatus
        stripeStatusHistory
        stripeReceiptUrl
        drinkCost
        fees
        menuItemDescription
        menuItemName
        stripePaymentIntentID
        tip
        purchaserID
        receiverID
        vendorsID
        vendorNotes
        vendorPaymentID
        nonMemberToken
        nonMemberPhone
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateMenuItemsSentToBar = /* GraphQL */ `
  subscription OnUpdateMenuItemsSentToBar(
    $filter: ModelSubscriptionMenuItemsSentToBarFilterInput
  ) {
    onUpdateMenuItemsSentToBar(filter: $filter) {
      receiverName
      id
      itemID
      item {
        id
        category
        personalMessage
        state
        stateHistory
        stripeStatus
        stripeStatusHistory
        stripeReceiptUrl
        drinkCost
        fees
        menuItemDescription
        menuItemName
        stripePaymentIntentID
        tip
        purchaserID
        receiverID
        vendorsID
        vendorNotes
        vendorPaymentID
        nonMemberToken
        nonMemberPhone
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteMenuItemsSentToBar = /* GraphQL */ `
  subscription OnDeleteMenuItemsSentToBar(
    $filter: ModelSubscriptionMenuItemsSentToBarFilterInput
  ) {
    onDeleteMenuItemsSentToBar(filter: $filter) {
      receiverName
      id
      itemID
      item {
        id
        category
        personalMessage
        state
        stateHistory
        stripeStatus
        stripeStatusHistory
        stripeReceiptUrl
        drinkCost
        fees
        menuItemDescription
        menuItemName
        stripePaymentIntentID
        tip
        purchaserID
        receiverID
        vendorsID
        vendorNotes
        vendorPaymentID
        nonMemberToken
        nonMemberPhone
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateVendorPayments = /* GraphQL */ `
  subscription OnCreateVendorPayments(
    $filter: ModelSubscriptionVendorPaymentsFilterInput
  ) {
    onCreateVendorPayments(filter: $filter) {
      id
      amount
      paymentDate
      menuItemsPurchased {
        nextToken
        __typename
      }
      vendorsID
      vendor {
        id
        status
        vendorDisplayStatus
        vendorCity
        vendorContactPhone
        vendorEmail
        vendorImage
        vendorName
        vendorState
        vendorStreet
        vendorZip
        waveDrinklinkFee
        feeScheduleID
        createdAt
        updatedAt
        __typename
      }
      adminUserID
      adminUser {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      paidBy
      invoiceNumber
      invoiceURL
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateVendorPayments = /* GraphQL */ `
  subscription OnUpdateVendorPayments(
    $filter: ModelSubscriptionVendorPaymentsFilterInput
  ) {
    onUpdateVendorPayments(filter: $filter) {
      id
      amount
      paymentDate
      menuItemsPurchased {
        nextToken
        __typename
      }
      vendorsID
      vendor {
        id
        status
        vendorDisplayStatus
        vendorCity
        vendorContactPhone
        vendorEmail
        vendorImage
        vendorName
        vendorState
        vendorStreet
        vendorZip
        waveDrinklinkFee
        feeScheduleID
        createdAt
        updatedAt
        __typename
      }
      adminUserID
      adminUser {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      paidBy
      invoiceNumber
      invoiceURL
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteVendorPayments = /* GraphQL */ `
  subscription OnDeleteVendorPayments(
    $filter: ModelSubscriptionVendorPaymentsFilterInput
  ) {
    onDeleteVendorPayments(filter: $filter) {
      id
      amount
      paymentDate
      menuItemsPurchased {
        nextToken
        __typename
      }
      vendorsID
      vendor {
        id
        status
        vendorDisplayStatus
        vendorCity
        vendorContactPhone
        vendorEmail
        vendorImage
        vendorName
        vendorState
        vendorStreet
        vendorZip
        waveDrinklinkFee
        feeScheduleID
        createdAt
        updatedAt
        __typename
      }
      adminUserID
      adminUser {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      paidBy
      invoiceNumber
      invoiceURL
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateFeeSchedule = /* GraphQL */ `
  subscription OnCreateFeeSchedule(
    $filter: ModelSubscriptionFeeScheduleFilterInput
  ) {
    onCreateFeeSchedule(filter: $filter) {
      id
      fee
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateFeeSchedule = /* GraphQL */ `
  subscription OnUpdateFeeSchedule(
    $filter: ModelSubscriptionFeeScheduleFilterInput
  ) {
    onUpdateFeeSchedule(filter: $filter) {
      id
      fee
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteFeeSchedule = /* GraphQL */ `
  subscription OnDeleteFeeSchedule(
    $filter: ModelSubscriptionFeeScheduleFilterInput
  ) {
    onDeleteFeeSchedule(filter: $filter) {
      id
      fee
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateVendorRequestAccess = /* GraphQL */ `
  subscription OnCreateVendorRequestAccess(
    $filter: ModelSubscriptionVendorRequestAccessFilterInput
  ) {
    onCreateVendorRequestAccess(filter: $filter) {
      id
      contactName
      contactEmail
      contactPhone
      vendorName
      vendorContactPhone
      vendorStreet
      vendorCity
      vendorState
      vendorZip
      contactsMade
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateVendorRequestAccess = /* GraphQL */ `
  subscription OnUpdateVendorRequestAccess(
    $filter: ModelSubscriptionVendorRequestAccessFilterInput
  ) {
    onUpdateVendorRequestAccess(filter: $filter) {
      id
      contactName
      contactEmail
      contactPhone
      vendorName
      vendorContactPhone
      vendorStreet
      vendorCity
      vendorState
      vendorZip
      contactsMade
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteVendorRequestAccess = /* GraphQL */ `
  subscription OnDeleteVendorRequestAccess(
    $filter: ModelSubscriptionVendorRequestAccessFilterInput
  ) {
    onDeleteVendorRequestAccess(filter: $filter) {
      id
      contactName
      contactEmail
      contactPhone
      vendorName
      vendorContactPhone
      vendorStreet
      vendorCity
      vendorState
      vendorZip
      contactsMade
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
