import {NumericFormat} from 'react-number-format';
import {formatMoney} from '../../utils/utils';
import moment from 'moment'

export const drinkColumns = [
    {
        field: "menuItemName",
        headerName: "Drink",
        width: 230,
    },
    {
        field: "drinkCost",
        headerName: "Price",
        width: 100,
        renderCell: (params) => {
            return (
                <div>
                    <NumericFormat value={formatMoney(params.row.drinkCost).toString()} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                </div>
            );
        },
    },
    {
        field: "tip",
        headerName: "Tip",
        width: 100,
        renderCell: (params) => {
            return (
                <div>
                    <NumericFormat value={formatMoney(params.row.tip).toString()} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                </div>
            );
        },
    },
    {
        field: "total",
        headerName: "Total",
        width: 100,
        renderCell: (params) => {
            return (
                <div>
                    <NumericFormat value={formatMoney(params.row.tip + params.row.drinkCost).toString()} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                </div>
            );
        },
    },
    {
        field: "state",
        headerName: "State",
        width: 120,
        renderCell: (params) => {
            const thisClassname = `${params.row.status}Drink`;
            return (
                <div
                    className={thisClassname}
                >
                    {params.row.state}
                </div>
            );
        },
    },
    {
        //field: "statusDate",
        headerName: "Redeemed Date",
        width: 200,
        renderCell: (params) => {
            const thisClassname = `${params.row.status}Drink`;
            const stateHistory = JSON.parse(params.row.stateHistory);
            return (
                <div
                    className={thisClassname}
                >
                    {moment(stateHistory[stateHistory.length - 1].date).format('MMM Do YYYY HH:MM:SS')}
                </div>
            );
        },
    },

];