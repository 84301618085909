import * as React from 'react';
import SidebarDrinklinkAdmin from "../../components/sidebar/SidebarDrinklinkAdmin";
import TopNavBar from "../Landing/topNavBar";
import Footer from "../Landing/footer";
import DrinklinkAdminVendorsPaidDatatable from './drinklink-admin-vendors-paid';
const VendorsPaid = () => {
    return (
        <>
          <TopNavBar />
            <div className="list">
                <SidebarDrinklinkAdmin/>
                <div className="listContainer">
                  <DrinklinkAdminVendorsPaidDatatable />
                </div>
            </div>
          <Footer />
        </>
      )
}

export default VendorsPaid