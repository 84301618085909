import {NumericFormat} from 'react-number-format';
import {formatMoney} from '../../utils/utils';
import moment from 'moment'

export const drinkColumns = [
  {
    field: "invoiceNumber",
    headerName: "Invoice Number",
    width: 230,
  },
  {
    field: "invoiceDate",
    headerName: "Date",
    width: 150,
    renderCell: (params) => {
      return (
        <div>
          {moment(params.row.invoiceDate).format('MMM Do YYYY')}
        </div>
      );
    },
  },
  {
    field: "paidBy",
    headerName: "Payment Method",
    width: 200,
    renderCell: (params) => {
      return (
        <div>
          {params.row.paidBy}
        </div>
      );
    },
  },
  {
    field: "adminUser",
    headerName: "Payment By",
    width: 200,
    renderCell: (params) => {
      return (
        <div>
          {params.row.adminUser.fName} {params.row.adminUser.lName}
        </div>
      );
    },
  },
  {
    field: "amount",
    headerName: "Amount",
    width: 100,
    renderCell: (params) => {
      return (
        <div>
          <NumericFormat value={formatMoney(params.row.amount).toString()} displayType={'text'} thousandSeparator={true} prefix={'$'} />
        </div>
      );
    },
  },
];