import "./vendors-owed-datatable.scss";
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from "@mui/x-data-grid";
import styled from 'styled-components';
import { useEffect, useState, useContext } from "react";
import {API, graphqlOperation} from "aws-amplify";
import { AuthContext } from "../../context/AuthContext";
import { RotatingLines } from 'react-loader-spinner';
import {vendorDataColumns} from './vendorsAccessRequestsDatatableSource'
import VendorAccessDetailsModal from './VendorAccessDetailsModal';
import {listVendorRequestAccesses} from "../../graphql/queries";
import {updateVendorRequestAccess} from "../../graphql/mutations";

const ActionButtonsWrap = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`

const DrinklinkAdminVendorAccessRequestsdDatatable = () => {
    const [data, setData] = useState([]);
    const [view, setView] = useState('vendors')
    const [modalType, setModalType] = useState('notes')
    const [requestSelected, setRequestSelected] = useState()
    const [isLoading, setIsLoading] = useState(true);
    const [updateContactPointsModalOpen, setUpdateContactPointsModalOpen] = useState(false);

    const {currentUser} = useContext(AuthContext);
    const navigate = useNavigate();

    const handleModalClose = () => setUpdateContactPointsModalOpen(false);

    const fetchTheData = async () => {  
        // get all drinks in redeemed state.
        const vendorRequestsReturn = await API.graphql(graphqlOperation(
            listVendorRequestAccesses, {
                filter: {
                    state: {eq: 'active'}
                }
            }
        ))
        let vendorRequests = vendorRequestsReturn.data.listVendorRequestAccesses.items
        setData(vendorRequests);
        setIsLoading(false)
    }

    const handleModalSubmit = async (newNote) => {
        let newNotesSection = [];
        if (!(requestSelected.contactsMade === null || requestSelected.contactsMade === undefined || requestSelected.contactsMade === '')) {
            const notesData = JSON.parse(requestSelected.contactsMade)
            newNotesSection = [...notesData]
        }

        const newFullNote = {
            note: modalType === 'notes' ? newNote : 'Marked Completed',
            date: new Date().toISOString(),
            updatedBy: currentUser.email
        }
            
        newNotesSection.push(newFullNote)
        console.log('newNotesSection', newNotesSection)
        const updateObject = {
            id: requestSelected.id,
            contactsMade: JSON.stringify(newNotesSection),
            ...(modalType === 'complete') && {state: 'completed'}
        }
        console.log('updateObject', updateObject)
        try {
            await API.graphql(graphqlOperation(updateVendorRequestAccess, {input: updateObject}))
            await fetchTheData()
        } catch (e) {
            console.log('error updating notes', e)
        }
        setUpdateContactPointsModalOpen(false)
    }

    useEffect(() => {
        const fetchData = async () => {
            fetchTheData()
        }
        fetchData()
    }, [])

    const handleViewDetails = (row) => {
        setModalType('notes')
        setRequestSelected(row)
        setUpdateContactPointsModalOpen(true)
        // open modal and show details
    }

    const handleMarkComplete = async (row) => {
        setRequestSelected(row)
        setModalType('complete')
        setUpdateContactPointsModalOpen(true)
    }

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 250,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <div 
                            className="viewButton"
                            onClick={() => handleViewDetails(params.row)}
                        >   
                            View Details
                        </div>
                        <div 
                            className="viewButton"
                            onClick={() => handleMarkComplete(params.row)}
                        >   
                            Mark Complete
                        </div>
                    </div>
                );
            },
        },
    ];

    const returnView = () => {
        return (
            <>
                <DataGrid
                    className="datagrid"
                    rows={data}
                    columns={vendorDataColumns.concat(actionColumn)}
                    pageSize={9}
                    rowsPerPageOptions={[25]}
                    //checkboxSelection
                />
            </>
        )
    }

    console.log('currentUser', currentUser)

    return (
        <>
            <div className="datatable">
                <div className="datatableTitle">
                    Vendors Requesting Access
                    {view === 'details' && <ActionButtonsWrap>
                        <div className="dataTableTitleButtons">
                            <div onClick={() => setView('vendors')} className="link">
                                Back to Vendors
                            </div>
                        </div>
                        <div className="dataTableTitleButtons">
                            <div onClick={() => setUpdateContactPointsModalOpen(true)} className="link">
                                Create Invoice and Pay Vendor
                            </div>
                        </div>
                    </ActionButtonsWrap>}
                    {/* { userVendorSelected.role !== 'viewer' && 
                        <div className="dataTableTitleButtons">
                            <div onClick={() => handleAddUser(null, 'add-user')} className="link">
                                Add New User
                            </div>
                        </div>
                    } */}
                    <div className="dataTableSearch">
                        {/* <Searchbar
                            style={{fontSize: 20}}
                            icon="account-search"
                            placeholder={'Enter name or email'}
                            value={searchText}
                            onChangeText={(txt) => {
                                onChangeSearchText(txt)
                            }}
                        /> */}
                    </div>
                </div>
                {!isLoading ?
                    <>
                        {returnView()}
                    </>
                    
                    :
                    <div className='loading-gif'>
                        <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="96"
                            visible={true}
                        />
                    </div>
                }
            </div>
            { updateContactPointsModalOpen &&
                <VendorAccessDetailsModal
                    modalState={updateContactPointsModalOpen}
                    handleModalClose={handleModalClose}
                    handleFormSubmit={handleModalSubmit}
                    vendorRequestData={requestSelected}
                    modalType={modalType}
                />
            }
        </>
    );
}

export default DrinklinkAdminVendorAccessRequestsdDatatable