import moment from 'moment';

const getLastContactDate = (data) => {
  if (!data) {
    return 'No Contacts Made'
  }
  const contacts = JSON.parse(data)
  // get the last contact date
  let mostRecentContact = contacts[contacts.length - 1]
  for (let i = contacts.length - 1; i >= 0; i--) {
    if (contacts[i].date > mostRecentContact.date) {
      mostRecentContact = contacts[i]
    }
  }
  return moment(mostRecentContact.date).format('MM/DD/YYYY hh:mm A')
}

const getClass = (data) => {
  if (!data) {
    return 'noContactsMade'
  }
  return 'contactsMade'
}

export const vendorDataColumns = [
  {
    field: "vendorName",
    headerName: "Vendor",
    width: 250,
  },
  {
    field: "contactName",
    headerName: "Contact",
    width: 200,
  },
  {
    field: "contactPhone",
    headerName: "Phone",
    width: 120,
  },
  {
    field: "contactsMade",
    headerName: "Last Contact Date",
    width: 200,
    renderCell: (params) => {
      const thisClassname = `${getClass(params.row.contactsMade)}`;
      return (
        <div className={thisClassname}>
          {getLastContactDate(params.row.contactsMade)}
        </div>
      );
    },
  },
  {
    field: "vendorAddress",
    headerName: "Address",
    width: 200,
    renderCell: (params) => {
      return (
        <div>
          {`${params.row.vendorCity}, ${params.row.vendorState}`}
        </div>
      );
    },
  },
];