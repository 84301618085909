//import "./list.scss";
import * as React from 'react';
import SidebarDrinklinkAdmin from "../../components/sidebar/SidebarDrinklinkAdmin";
import DrinklinkAdminVendorAccessRequestsdDatatable from "./drinklink-admin-vendor-access-requests";
import TopNavBar from "../Landing/topNavBar";
import Footer from "../Landing/footer";

const VendorRequestAccess = () => {

  return (
    <>
      <TopNavBar />
        <div className="list">
            <SidebarDrinklinkAdmin/>
            <div className="listContainer">
              <DrinklinkAdminVendorAccessRequestsdDatatable />
            </div>
        </div>
      <Footer />
    </>
  )
}

export default VendorRequestAccess