import * as React from 'react';
import {useState} from 'react';
import Select from 'react-select';
import "./createInvoiceModal.scss";
import styled from 'styled-components';
import Modal from '@mui/material/Modal';

const SaveItemButton = styled.button`
    justify-content: center;
    align-items: center;
    padding: 0px;
    border-radius: 6px;
`;

const SaveItemsButtonsRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
`;

const typeData = [
    {value: 'check', label: 'Check'},
    {value: 'cash', label: 'Cash'},
    {value: 'credit', label: 'Credit'},
    {value: 'ach', label: 'ACH'}
]
    

const CreateInvoiceModal = ({modalState, handleModalClose, handleFormSubmit}) => {
    const [formData, setFormData] = useState({
        paymentType: typeData[0].value,
        paymentNumber:''
    });

    const handleInputChange = (event) => {
        //setErrorMessage([]);
        const value = event.target.value;
         
        setFormData({
            ...formData,
            [event.target.name] : value
        })
    }

    const handleSetPaymentType = (theSelection) => {
        setFormData({
            ...formData,
            paymentType: theSelection.value
        })
    }

    const handleSaveChanges = () => {
        console.log('formData in handleSaveChanges', formData)
        handleFormSubmit(formData);
    }
    console.log('in createInvoiceModal', modalState)

    return (
      <Modal
        open={modalState}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className='create-invoice-item-wrapper'>Hello
            <form>
                <div className="formInput" key='homeState'>
                    <label>Home State</label>
                    <Select 
                        options={typeData} 
                        name="homeState"
                        onChange={handleSetPaymentType}
                        defaultValue={typeData[0]}
                    />
                </div>
                <div className="formInput" key='paymentNumber'>
                    <label>Payment Identifier</label>
                    <input 
                        type='text' 
                        placeholder="1234"
                        name='paymentNumber'
                        value={formData.paymentNumber}
                        onChange={handleInputChange}
                    />
                </div>
                <SaveItemsButtonsRow>
                    <SaveItemButton type="button" onClick={handleModalClose} >Cancel</SaveItemButton>
                    <SaveItemButton type='button' onClick={() => handleSaveChanges()}>Next</SaveItemButton>
                </SaveItemsButtonsRow>
            </form>
        </div>
      </Modal>
    )

}

export default CreateInvoiceModal;