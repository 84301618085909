import * as React from 'react';
import { useContext, useState, useRef, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {API, graphqlOperation} from "aws-amplify";

import { AuthContext } from '../../context/AuthContext';
import EditDetailsModal from './editDetailsModal';
import { updateUsers } from '../../graphql/mutations';
//import { db, Storage } from '../../firebase';


const MyDetailsWrapper = styled.div`
    height: 80vh;
    width: 80%;
    margin-left: 10%;
`;

const MyDetailsContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (max-width: 600px) {
        margin-top: 10px;
        padding: 0px;
        flex-direction: column;
    }
`;

const ProfileImageWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-right: 40px;
`

const ProfileData = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%
    justify-content: center;
    align-items: flex-start;
    flex: 1;
`;

const EmptyAvatarImage = styled.div`
    background-color: green;
    width: 100px;
    border-radius: 50px; 
`;

const ImageHolder = styled.img`
    width: 200px;
    height: 200px;
    border-radius: 100px;
`;

const UpdateImageButton = styled.div`
    background-color: #844454;
    color: white;
    padding: 10px 20px;
    border-radius: 30px;
    width: 160px;
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
`;

const SaveImageButton = styled.div`
    background-color: #2A3841;
    color: white;
    padding: 10px 20px;
    border-radius: 30px;
    width: 160px;
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
`;

const MyDataTitle = styled.div`
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: bold;
`;

const ConstMyDataItem = styled.div`
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 10px;
`;

const BirthdayYearNote = styled.div`
    padding-left: 10px;
    color: #844454;
`

const EditUserDetailsButton = styled.span`
    font-size: 16px;
    margin-left: 30px;
    color: white;
    background-color: #2A3841;
    padding: 5px 10px;
    border-radius: 10px;
`;

const MyDataTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;
`


const MyDetails = () => {
    const { currentUser, dispatch: authDispatch } = useContext(AuthContext);
    const [avatarImage, setAvatarImage] = useState(currentUser.avatarImage ? currentUser.avatarImage : null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [imageButtonState, setImageButtonState] = useState('change');
    const hiddenFileInput = useRef(null);

    const handleEditModalClose = () => setEditModalOpen(false);

    const onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            setAvatarImage(URL.createObjectURL(img));
        }
        setImageButtonState('save');
    }

    const handleButtonClick = event => {
        hiddenFileInput.current.click();
    }

    const updateUserData = async (data, type) => {
        try {
            await API.graphql(graphqlOperation(updateUsers, {input: data}));
            return true;
        } catch (e) {
            console.log('error updating user', e);
        }
        
        return false;
    }

    const handleSaveImageClick = async () => {
        console.log('saving image');
        if (avatarImage) {
            const imgStorageName = `${currentUser.uid}-${new Date().getTime()}.jpg`;

            try {
                // const refToImg = ref(Storage, imgStorageName); //how the image will be addressed inside the storage
            
                // //convert image to array of bytes
                // const img = await fetch(avatarImage);
                // const bytes = await img.blob();

                // await uploadBytesResumable(refToImg, bytes); //upload images
                // const avatarImageURL = await getDownloadURL(refToImg);

                // const res = await updateUserData({avatarImage: avatarImageURL});
            
                // if (res) {
                //     const newCurrentUser = { ...currentUser}
                //     newCurrentUser.avatarImage = avatarImageURL;
                //     authDispatch({ type: "UPDATE_USER", payload: {...newCurrentUser}});
                // }
            } catch (e) {
                console.log('error', e);
            }
            setImageButtonState('change')
        }
        return;
    }

    const handleEditFormSubmit = async (formData) => {
        console.log('formData', formData);
        const newUserData = {};
        const newCurrentUser = {...currentUser};

        if (formData.fName !== currentUser.fName) {
            newUserData.fName = formData.fName;
            newCurrentUser.fName = formData.fName;
        }
        if (formData.lName !== currentUser.lName) {
            newUserData.lName = formData.lName;
            newCurrentUser.lName = formData.lName;
        }
        if (formData.username !== currentUser.username) {
            newUserData.lowerCaseUsername = formData.lowerCaseUsername;
            newCurrentUser.lowerCaseUsername = formData.lowerCaseUsername;
        }
        if (formData.username !== currentUser.username) {
            newUserData.username = formData.username;
            newCurrentUser.username = formData.username;
        }
        if (formData.email !== currentUser.email) {
            newUserData.email = formData.email;
            newCurrentUser.email = formData.email;
        }
        if (formData.homeCity !== currentUser.homeCity) {
            newUserData.homeCity = formData.homeCity;
            newCurrentUser.homeCity = formData.homeCity;
        }
        if (formData.homeState !== currentUser.homeState) {
            newUserData.homeState = formData.homeState;
            newCurrentUser.homeState = formData.homeState;
        }
        if (formData.birthDate !== currentUser.birthDate) {
            newUserData.birthDate = formData.birthDate;
            newCurrentUser.birthDate = formData.birthDate;
        }
        newUserData.id = currentUser.id;

        console.log('newUserData', newUserData);
        console.log('newCurrentUser', newCurrentUser);

        const res = await updateUserData(newUserData);
        if (res) {
            authDispatch({ type: "UPDATE_USER", payload: {...newCurrentUser}});
        }
        setEditModalOpen(false);
    }

    return (
        <MyDetailsWrapper>
            <MyDetailsContainer>
                    <ProfileImageWrapper>
                        { avatarImage ?
                            <ImageHolder src={avatarImage} alt="avatar" />
                            :
                            <EmptyAvatarImage />
                        }
                        
                            <UpdateImageButton onClick={() => handleButtonClick()}>Change Profile Image</UpdateImageButton>
                            { imageButtonState === 'save' &&
                                <SaveImageButton onClick={() => handleSaveImageClick()}>Save New Image</SaveImageButton>
                            }
                        <input 
                            type="file" 
                            style={{ display: "none"}} 
                            name="myImage" 
                            ref={hiddenFileInput}
                            onChange={onImageChange} 
                        />
                    </ProfileImageWrapper>
                    
                <ProfileData>
                    <MyDataTitleContainer>
                        <MyDataTitle>My data</MyDataTitle>
                        <EditUserDetailsButton onClick={() => setEditModalOpen(true)}>Edit my data</EditUserDetailsButton>
                    </MyDataTitleContainer>
                    <ConstMyDataItem>Name: {currentUser.fName} {currentUser.lName}</ConstMyDataItem>
                    <ConstMyDataItem>Username: {currentUser.username ? currentUser.username : ''}</ConstMyDataItem>
                    <ConstMyDataItem>Email: {currentUser.email ? currentUser.email : ''}</ConstMyDataItem>
                    <ConstMyDataItem>Home City: {currentUser.homeCity ? currentUser.homeCity : ''}</ConstMyDataItem>
                    <ConstMyDataItem>Birthday: {currentUser.birthDate ? moment(currentUser.birthDate).format('YYYY-MM-DD') : ''}</ConstMyDataItem>
                    <BirthdayYearNote>*year is only ever shown to you</BirthdayYearNote>
                </ProfileData>
            </MyDetailsContainer>
            {editModalOpen && 
                <EditDetailsModal 
                    currentUser={currentUser}
                    modalState={editModalOpen}
                    handleModalClose={handleEditModalClose}
                    handleFormSubmit={handleEditFormSubmit}
                />
            }
        </MyDetailsWrapper>
    )
}

export default MyDetails;